import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import {
  makeStyles,
  Dialog,
  DialogContent
} from "@material-ui/core";
import clsx from "clsx";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles((theme) => ({
  mainDialogCls: {
    width: "400px !important",
    maxHeight: "500px !important",
    maxWidth: "unset",
    [theme.breakpoints.down("xs")]: {
      width: "90% !important",
    },
  },
  dialogCls: {
    "&>h3": {
      textAlign: "center",
      marginTop: 0,
      marginBottom: 5,
      cursor: "pointer",
      "&>button": {
        "&>span": {
          fontSize: 16,
          fontWeight: 500,
        },
      },
    },
    "&>p": {
      margin: 0,
      textAlign: "center",
      fontSize: 14,
      marginBottom: 10,
      "&>span": {
        color: "#3AA3F9",
        cursor: "pointer",
      },
    },
    "&>form": {
      "&>p": {
        margin: 0,
        textAlign: "right",
        fontSize: 14,
        marginBottom: 10,
        "&>span": {
          color: "#3AA3F9",
          cursor: "pointer",
        },
      },
    },
  },
  befaultButtonCls: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 20,
    "&>button": {
      "&:nth-of-type(1)": {
        marginRigth: "auto",
        display: "block",
      },
      "&:nth-of-type(2)": {
        marginLeft: "auto",
        display: "block",
        "&>span": {
          display: "flex",
          alignItems: "center",
          "&>div": {
            width: "15px !important",
            height: "15px !important",
            marginRight: 10,
            color: "#FFF",
          },
        },
      },
    },
  },
}));

function WhatsAppInquiryDailog(props) {
  const classes = useStyles();
  const {
    userSettingsDetails,
    openDailog,
    dialogStatus,
  } = props;

  const handleWhatsppClick = (whatsappNumber) => {
    const whatsappMessage = userSettingsDetails?.inquiry_whatspp_message;
    if (whatsappNumber){
      const url = `https://wa.me/${whatsappNumber}?text=${whatsappMessage}`;
      let height = 500;
      let width = 500;
      var left = (window.screen.width - width ) / 2 ;
      var top = (window.screen.height - height ) / 2 ;
      // window.location.href = url;
      window.open(url, "WhatsApp Inquiry", `height=${height},width=${width},top=${top},left=${left}`);
    }else {
      alert("Something went wrong")
    }
  }

  return (
    <div className={clsx(classes.rootFooter)}>
      
      <Dialog
        onClose={() => openDailog(false)}
        aria-labelledby="customized-dialog-title"
        open={dialogStatus}
        classes={{
          paper: classes.mainDialogCls,
        }}
      >
        <DialogContent dividers>
          <div className={classes.dialogCls}>
            <h3>
              WhatsApp Inquiry
            </h3>
            <List className={classes.root}>
              
              {userSettingsDetails?.inquiry_whatspp_number && JSON.parse(userSettingsDetails.inquiry_whatspp_number).map(whatsapp =>
                (whatsapp.number && <ListItem style={{cursor: 'pointer'}} onClick={() => handleWhatsppClick(whatsapp.number)} >
                  <ListItemAvatar>
                    <Icon htmlColor="#25D366" fontSize="large" />
                  </ListItemAvatar>
                  <ListItemText primary={whatsapp.name} secondary={whatsapp.number}  />
                </ListItem>)
              )}
            </List>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userSettingsDetails: state.user.userSettingsDetails
});

const mapDispatchToProps = (dispatch) => (
  {

  })

export default connect(mapStateToProps)(WhatsAppInquiryDailog);
