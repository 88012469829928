import { createStore, applyMiddleware } from "redux";
import reducer from "./reducers";
// import {composeWithDevTools} from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducer,
   applyMiddleware(sagaMiddleware)
);
sagaMiddleware.run(rootSaga);

export default store;
