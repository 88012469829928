import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {
  makeStyles,
  TextField,
  InputAdornment,
  Grid,
  Menu,
  MenuItem,
  Avatar,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import Search from "@material-ui/icons/Search";
import AvatarUser from "react-avatar";
import clsx from "clsx";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import LoginDailog from "./LoginDailog";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  START_GET_ADMIN_VIDEO_LINK,
  EMPTY_USER_INIT_STATE,
  START_GET_USER_SETTINGS,
} from "../../actions/user";
import { START_GET_SEARCH_PRODUCT_LIST } from "../../actions/product";
import CloseIcon from "@material-ui/icons/Close";
import { replaceAllString } from "../../utils/customHooks";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import WhatsAppInquiryDailog from "./WhatsAppInquiryDailog";
import RedirectionMiddleWare from "./RedirectionMiddleware";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    "&>header": {
      boxShadow: "none",
     
    },
  },
  toolbar: {
    minHeight: 70,
    backgroundColor: "#FFF",
    color: "#26262D",
    padding: 0,
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-evenly",
      minHeight: 75,
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: 60,
      position: "fixed",
      width: "100%",
      padding: 0,
      zIndex: 2000,
    },
  },
  avatarUserName: {
    display: "inline-block",
    borderRadius: 50,
    verticalAlign: "unset !important",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    "&>img": {
      marginLeft: -2,
      marginTop: -3,
      width: 35,
      height: 35,
    },
  },
  webLogoCls: {
    width: "auto",
    height: 40,
    marginTop: 8,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobLogoCls: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: 50,
      //paddingLeft: 15,
    },
    [theme.breakpoints.down(600)]: {
      display: "block",
      width: 30,
      paddingLeft: 15,
    },
  },
  logoMainCls: {
    textAlign: "center",
    width: "100%",
    "&>a": {
      fontFamily: "inherit",
      textDecoration: "none",
      color: "#353b66",
      fontSize: 24,
      // lineHeight: "49px",
      "&:nth-of-type(1)": {
        "&>img": {
          width: '90px'
        },
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      "&:nth-of-type(2)": {
        "&>img": {
          width: '90px'
        },
        display: "none",
        [theme.breakpoints.down("sm")]: {
          display: "block",
          textAlign: "center",
        },
      },
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
  },
  mobLogoMainCls: {
    textAlign: "center",
    width: "100%",
    "&>a": {
      fontFamily: "inherit",
      textDecoration: "none",
      color: "#353b66",
      fontSize: 24,
      // lineHeight: "49px",
      "&>img": {
        width: '65px'
      }
    },
  },
  menuMainCla: {
    display: "flex",
    //alignItems: "flex-end",
    width: "100%",
    justifyContent: "center",
    marginTop: 0,
  },
  searchBarInput: {
    maxHeight: 30,
    maxWidth: "100%",
    // display: "none", // remove later on
    "&>div": {
      color: "#97979B",
      opacity: 1,
      fontWeight: 500,
      fontSize: 14,
      borderRadius: 50,
      maxHeight: 39,
      backgroundColor: "#f2f2f2",
      "&>div>svg": {
        width: 22,
        height: 22,
        opacity: 0.9,
      },
    },
    "& input": {
      // padding: 5,
      zIndex: 1,
      width: 600,
      padding: "6px 10px 6px 0px",
      fontSize: 14,
    },
    "& fieldset": {
      //zIndex: 0,
      border: 0,
      // backgroundColor: "#002157",
      //backgroundColor: "rgba(255, 255, 255, 0.2)"
    },
  },
  headerLogoCls: {
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      display: "flex",
    },
  },
  webSearchBoxCls: {
    display: 'flex',
    margin: "12px 0px",
    "&>div": {
      width: "65% !important",
      [theme.breakpoints.down(600)]: {
        width: "100% !important",
      },
      [theme.breakpoints.down(360)]: {
        width: "95% !important",
      },
    },
  },
  mobLogoBoxCls: {
    margin: "5px 0px",
    "&>div": {
      [theme.breakpoints.down(600)]: {
        width: "100% !important",
      },
      [theme.breakpoints.down(360)]: {
        width: "95% !important",
      },
    },
  },
  mobSearchBoxCls: {
    margin: "5px 0px 14px 0px",
    "&>div": {
      width: "65% !important",
      [theme.breakpoints.down(600)]: {
        width: "90% !important",
      },
      [theme.breakpoints.down(360)]: {
        width: "95% !important",
      },
    },
  },
  webUserIconCla: {
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      // display: "none",
    },
  },
  searchBarBtn: {
    color: "#FFF",
    background: "#3AA3F9",
    padding: 17,
    borderRadius: "0px 22px 22px 0px",
    marginRight: "-15px",
    cursor: "pointer",
  },
  notiImgIconCls: {
    backgroundColor: "#d11124",
    alignItems: "flex-start",
    left: "calc(100% - .6rem)",
    height: "0.9rem",
    borderRadius: ".8rem",
    padding: "0 .25rem",
    minWidth: ".5rem",
    position: "absolute",
    border: "2px solid #FFF",
    color: "#FFF",
    fontSize: 12,
    marginTop: -12,
    zIndex: 1,
    fontWeight: 600,
    lineHeight: 1.3,
    textAlign: "center",
  },
  notificationMainCla: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "4px solid #353b66",
    padding: "0px 5px",
    borderRadius: 50,
    height: 31,
    width: 21,
    position: "relative",
    marginRight: 15,
    cursor: "pointer",
    // [theme.breakpoints.down("xs")]: {
    //   display: "none",
    // },
    "&>svg": {
      fontSize: 21,
    },
  },
  userIconMainCla: {
    [theme.breakpoints.down("sm")]: {
      // display: "none",
    },
  },
  profileImage: {
    background: "#FA601729 0% 0% no-repeat padding-box",
    borderRadius: "50%",
    border: "4px solid #353b66",
    //overflow: "hidden",
    position: "relative",
    width: 30,
    height: 30,
  },
  mainWebSearchBoxCls: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  mainMobSearchBoxCls: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
    },
  },
  mobMenuBoxCls: {
    display: "flex",
    alignItems: "center",
    "&>div": {
      "&>div": {
        "&>svg": {
          width: 60,
          height: 35,
        },
      },
    },
  },
  mobdrowerMenuListCls: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: 170,
      marginTop: 100,
    },
    "&>svg": {
      cursor: "pointer",
      display: "block",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "auto",
      },
    },
    "&>div": {
      padding: "10px 10px 0px 10px",
    },
  },
}));

function Header(props) {
  let navigate = useNavigate()
  const classes = useStyles();
  const history = useNavigate();
  const {
    productDetailsData,
    productCartCount,
    videoLinkData,
    videoLinkDataIsLoading,
    userEmptyInitStateAction,
    adminVideoLinkAction,
    getSearchProductListAction,
    globalSearchText,
    getUserSettingsAction
  } = props;

  const [searchText, setSearchText] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [headerAnchorEl, setHeaderAnchorEl] = useState(null);
  const [mobileHeaderAnchorEl, setMobileHeaderAnchorEl] = useState(null);
  const [loginDailogStatus, setLoginDailogStatus] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [whatsAppDialogStatus, setWhatsAppDialogStatus] = useState(false);
  const [cartCount, setCartCount] = useState(
    window.localStorage.getItem("cartCount")
  );
  

  useEffect(() => {
    if (window.location.pathname.split("/")[1] === "search-product") {
      // setSearchText(
      //   replaceAllString(window.location.pathname.split("/")[2], "%20", " ")
      // );
      userEmptyInitStateAction(
        "globalSearchText",
        replaceAllString(window.location.pathname.split("/")[2], "%20", " ")
      );
    } else {
      // setSearchText("");
      userEmptyInitStateAction("globalSearchText", "");
    }
    getUserSettingsAction();
  }, []);
  useEffect(() => {
    if (productDetailsData?.profile) {
      if (
        productDetailsData?.profile !== undefined &&
        productDetailsData?.profile !== null &&
        productDetailsData?.profile !== ""
      ) {
        setUserProfile(productDetailsData?.profile);
      }
      if (productDetailsData?.cart_count) {
        setCartCount(
          productDetailsData?.cart_count ? productDetailsData?.cart_count : 0
        );
      }
    } else {
      setUserProfile("");
    }
  }, [productDetailsData]);

  useEffect(() => {
    if (productCartCount !== "" && productCartCount !== undefined) {
      setCartCount(productCartCount);
    }
  }, [productCartCount]);

  const handleSearchChange = (e) => {
    // setSearchText(e.target.value);
    userEmptyInitStateAction("globalSearchText", e.target.value);
  };
  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      if (e.target.value.trim() !== "") {
        getSearchProductListAction(e.target.value);
        history(`/search-product/${e.target.value}`);
      }
    }
  };

  const showHeaderLinks = (event) => {
    if (headerAnchorEl === null) {
      setHeaderAnchorEl(event.currentTarget);
    } else {
      setHeaderAnchorEl(null);
    }
  };

  const showMobileHeaderLinks = (status) => {
    setMobileHeaderAnchorEl(status);
  };

  const openLoginDailog = (status) => {
    setHeaderAnchorEl(null);
    setLoginDailogStatus(status);
  };

  const userLogoutAction = () => {
    window.localStorage.clear();
    window.location.reload();
  };

  const menuAction = (url) => {
    setHeaderAnchorEl(null);
    navigate(`/rd${url}`)
    setMobileHeaderAnchorEl(null);
    // history(url);
  };

  const searchClickAction = () => {
    getSearchProductListAction(globalSearchText);
    history(`/search-product/${globalSearchText}`);
  };

  const goTOUserDetailsAction = () => {
    if (
      window.localStorage.getItem("token") !== null &&
      window.localStorage.getItem("token") !== "" &&
      window.localStorage.getItem("token") !== undefined
    ) {
      history("/account/profile-information");
    } else {
      openLoginDailog(true);
    }
  };

  const handleVideoLinkClick = () => {
    setOpenMenu(!openMenu);
  };

  const openWhatsppDialog = (status) => {
    setWhatsAppDialogStatus(status)
  };

 

  useEffect(() => {
    adminVideoLinkAction();
  }, []);

  return (
    <div className={clsx(classes.root)} style={{position:"fixed",bottom:"unset",background:"white",zIndex:"10000",maxWidth:"1200px",width:"100%"}}>
      <AppBar position="static" color="secondary">
        <Toolbar
          component="nav"
          className={clsx(classes.root, classes.toolbar)}
        >
          <Grid container className={classes.mainMobSearchBoxCls}>
            <Grid item xs={3} className={classes.mobMenuBoxCls}>
              <div>
                <div>
                  <MenuIcon onClick={() => showMobileHeaderLinks(true)} />
                </div>
              </div>
            </Grid>
            <Grid item xs={5} className={classes.mobLogoBoxCls}>
              <div className={classes.mobLogoMainCls}>
                <Link to={"/home"}>
                  <img src={`${process.env.REACT_APP_IMG_URL}/images/logo-croped.png`} />
                </Link>
              </div>
            </Grid>
            <Grid item xs={4} className={classes.webUserIconCla}>
              <div className={classes.menuMainCla}>
                <div
                  className={classes.notificationMainCla}
                  onClick={openWhatsppDialog}
                >
                  <WhatsAppIcon htmlColor="#25D366" />
                </div>
                <div
                  className={classes.notificationMainCla}
                  onClick={() => {history("/cart");setWhatsAppDialogStatus(false)}}
                >
                  {cartCount > 0 && (
                    <span className={classes.notiImgIconCls}>{cartCount}</span>
                  )}
                  <ShoppingCartIcon />
                </div>
                {/*<div className={classes.userIconMainCla}>
                  <div className={clsx(classes.profileImage)}>
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        userProfile === ""
                          ? `${process.env.PUBLIC_URL}/defaultUser.png`
                          : userProfile
                      }
                      onClick={() => {
                        window.innerWidth > 600 && showMobileHeaderLinks(true);
                      }}
                      className={classes.avatarUserName}
                    />
                  </div>
                </div>*/}
              </div>
            </Grid>
            <Grid item xs={12} className={classes.mobSearchBoxCls}>
              <div
                style={{ textAlign: "center", width: "85%", margin: "auto" }}
              >
                <TextField
                  className={clsx(classes.searchBarInput)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {globalSearchText.trim() === "" && <Search />}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={clsx(
                          globalSearchText.trim() !== ""
                            ? classes.searchBarBtn
                            : ""
                        )}
                        onClick={() => searchClickAction()}
                      >
                        {globalSearchText.trim() !== "" && <Search />}
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Product"
                  onChange={handleSearchChange}
                  onKeyUp={handleKeyUp}
                  value={globalSearchText}
                  autoComplete="off"
                />
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.mainWebSearchBoxCls}>
            <Grid item xs={2} className={classes.headerLogoCls}>
              <div className={classes.logoMainCls}>
                <Link to={"/home"}>
                  {/* <img
                    className={classes.webLogoCls}
                    alt="Umwuga"
                    src={process.env.PUBLIC_URL + "/logo.png"}
                  /> */}
                  <img src={`${process.env.REACT_APP_IMG_URL}/images/logo-croped.png`} />
                </Link>
                <Link to={"/home"}>
                  {/* <img
                    className={classes.mobLogoCls}
                    alt="Umwuga"
                    src={process.env.PUBLIC_URL + "/logo.png"}
                  /> */}
                  <img src={`${process.env.REACT_APP_IMG_URL}/images/logo-croped.png`} />
                </Link>
              </div>
            </Grid>
            <Grid item xs={8} className={classes.webSearchBoxCls}>
              <div
                style={{ textAlign: "center", width: "85%", margin: "auto" }}
              >
                <TextField
                  className={clsx(classes.searchBarInput)}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {globalSearchText.trim() === "" && <Search />}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={clsx(
                          globalSearchText.trim() !== ""
                            ? classes.searchBarBtn
                            : ""
                        )}
                        onClick={() => searchClickAction()}
                      >
                        {globalSearchText.trim() !== "" && <Search />}
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Search Product"
                  onChange={handleSearchChange}
                  onKeyUp={handleKeyUp}
                  value={globalSearchText}
                  autoComplete="off"
                />
              </div>
            </Grid>
            <Grid item xs={2} className={classes.webUserIconCla}>
              <div className={classes.menuMainCla}>
                <div
                  className={classes.notificationMainCla}
                  onClick={openWhatsppDialog}
                >
                  <WhatsAppIcon htmlColor="#25D366" />
                </div>
                <div
                  className={classes.notificationMainCla}
                  onClick={() => {history("/cart");setWhatsAppDialogStatus(false)}}
                >
                  {cartCount > 0 && (
                    <span className={classes.notiImgIconCls}>{cartCount}</span>
                  )}
                  <ShoppingCartIcon />
                </div>
                <div className={classes.userIconMainCla}>
                  <div className={clsx(classes.profileImage)}>
                    {cartCount > 0 && window.innerWidth <= 600 && (
                      <span className={classes.notiImgIconCls}>
                        {cartCount}
                      </span>
                    )}
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        userProfile === ""
                          ? `${process.env.PUBLIC_URL}/defaultUser.png`
                          : userProfile
                      }
                      onClick={() => showMobileHeaderLinks(true)}
                      className={classes.avatarUserName}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <LoginDailog
        loginDailogStatus={loginDailogStatus}
        openLoginDailog={openLoginDailog}
      />
      <Drawer
        anchor={window.screen.width <= 600 ? "left" : "right"}
        open={mobileHeaderAnchorEl}
        onClose={() => showMobileHeaderLinks(false)}
      >
        <List className={classes.mobdrowerMenuListCls}>
          <CloseIcon onClick={() => showMobileHeaderLinks(false)} />
          {(window.localStorage.getItem("token") === null ||
            window.localStorage.getItem("token") === "" ||
            window.localStorage.getItem("token") === undefined) &&
            window.innerWidth <= 600 && (
              <ListItem button onClick={() => menuAction("/cart")}>
                <ListItemText primary={"Cart"} />
              </ListItem>
            )}
          {(window.localStorage.getItem("token") === null ||
            window.localStorage.getItem("token") === "" ||
            window.localStorage.getItem("token") === undefined) && (
            <ListItem button onClick={() => openLoginDailog(true)}>
              <ListItemText primary={"Login"} />
            </ListItem>
          )}
          {window.localStorage.getItem("token") !== null &&
            window.localStorage.getItem("token") !== "" &&
            window.localStorage.getItem("token") !== undefined && (
              <>
                <ListItem
                  button
                  onClick={() => menuAction("/account/profile-information")}
                >
                  <ListItemText primary={"My Profile"} />
                </ListItem>
                <ListItem
                  button
                  onClick={() => menuAction("/account/manage-addresses")}
                >
                  <ListItemText primary={"Manage Addresses"} />
                </ListItem>
                <ListItem button onClick={() => menuAction("/account/orders")}>
                  <ListItemText primary={"My Orders"} />
                </ListItem>
                <ListItem
                  button
                  onClick={() => menuAction("/account/my-wish-list")}
                >
                  <ListItemText primary={"My Wishlist"} />
                </ListItem>
                <ListItem button onClick={() => userLogoutAction()}>
                  <ListItemText primary={"Logout"} />
                </ListItem>
              </>
            )}
          <Divider />
          {videoLinkData.length > 0 && (
            <ListItem button onClick={() => menuAction("/gallery")}>
              <ListItemText primary="Gallery" />
            </ListItem>
          )}
          {videoLinkData.length > 0 && (
            <ListItem button onClick={() => handleVideoLinkClick()}>
              <ListItemText primary="Videos" />
              {openMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          )}
          {videoLinkData.length > 0 && (
            <Collapse in={openMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {videoLinkData.map((iteam, key) => (
                  <a
                    href={iteam?.video_url}
                    target="blank"
                    style={{ color: "rgba(0, 0, 0, 0.87)" }}
                  >
                    <ListItem button className={classes.nested} key={key}>
                      <ListItemText primary={iteam?.video_name} />
                    </ListItem>
                  </a>
                ))}
              </List>
            </Collapse>
          )}
          <ListItem button onClick={() => menuAction("/contact-us")}>
            <ListItemText primary={"Contact Us"} />
          </ListItem>
          <ListItem button onClick={() => menuAction("/about-us")}>
            <ListItemText primary={"About Us"} />
          </ListItem>
          <ListItem button onClick={() => menuAction("/faq")}>
            <ListItemText primary={"FAQ"} />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => menuAction("/payments")}>
            <ListItemText primary={"Payments"} />
          </ListItem>
          <ListItem button onClick={() => menuAction("/shipping")}>
            <ListItemText primary={"Shipping"} />
          </ListItem>
          <ListItem
            button
            onClick={() => menuAction("/cancellation-and-returns")}
          >
            <ListItemText primary={"Cancellation & Returns"} />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => menuAction("/return-policy")}>
            <ListItemText primary={"Return Policy"} />
          </ListItem>
          <ListItem button onClick={() => menuAction("/terms-of-use")}>
            <ListItemText primary={"Terms Of Use"} />
          </ListItem>
          <ListItem button onClick={() => menuAction("/security")}>
            <ListItemText primary={"Security"} />
          </ListItem>
          <ListItem button onClick={() => menuAction("/privacy")}>
            <ListItemText primary={"Privacy"} />
          </ListItem>
        </List>
      </Drawer>
      <WhatsAppInquiryDailog openDailog={openWhatsppDialog} dialogStatus={whatsAppDialogStatus}  />
    </div>
  );
}

const mapStateToProps = (state) => ({
  productDetailsData: state.user.productDetailsData,
  productCartCount: state.product.productCartCount,
  videoLinkData: state.user.videoLinkData,
  videoLinkDataIsLoading: state.user.videoLinkDataIsLoading,
  globalSearchText: state.user.globalSearchText
});

const mapDispatchToProps = (dispatch) => ({
  adminVideoLinkAction: () => dispatch({ type: START_GET_ADMIN_VIDEO_LINK }),
  getSearchProductListAction: (productName) =>
    dispatch({ type: START_GET_SEARCH_PRODUCT_LIST, productName }),
  userEmptyInitStateAction: (key, value) =>
    dispatch({ type: EMPTY_USER_INIT_STATE, key, value }),
  getUserSettingsAction: () => dispatch({ type: START_GET_USER_SETTINGS }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
