export const EMPTY_USER_INIT_STATE = "EMPTY_PRODUCT_INIT_STATE";

export const START_USER_SIGN_IN = "START_USER_SIGN_IN";
export const SUCCESS_USER_SIGN_IN = "SUCCESS_USER_SIGN_IN";
export const ERROR_USER_SIGN_IN = "ERROR_USER_SIGN_IN";

export const START_USER_SIGN_UP = "START_USER_SIGN_UP";
export const SUCCESS_USER_SIGN_UP = "SUCCESS_USER_SIGN_UP";
export const ERROR_USER_SIGN_UP = "ERROR_USER_SIGN_UP";

export const START_GET_USER_DETAILS = "START_GET_USER_DETAILS";
export const SUCCESS_GET_USER_DETAILS = "SUCCESS_GET_USER_DETAILS";
export const ERROR_GET_USER_DETAILS = "ERROR_GET_USER_DETAILS";

export const START_GET_BANNER_LIST = "START_GET_BANNER_LIST";
export const SUCCESS_GET_BANNER_LIST = "SUCCESS_GET_BANNER_LIST";
export const ERROR_GET_BANNER_LIST = "ERROR_GET_BANNER_LIST";

export const START_GET_SUB_BANNER_LIST = "START_GET_SUB_BANNER_LIST";
export const SUCCESS_GET_SUB_BANNER_LIST = "SUCCESS_GET_SUB_BANNER_LIST";
export const ERROR_GET_SUB_BANNER_LIST = "ERROR_GET_SUB_BANNER_LIST";

export const START_USER_ADDRESS_ACTION = "START_USER_ADDRESS_ACTION";
export const SUCCESS_USER_ADDRESS_ACTION = "SUCCESS_USER_ADDRESS_ACTION";
export const ERROR_USER_ADDRESS_ACTION = "ERROR_USER_ADDRESS_ACTION";

export const START_UPDATE_USER_INFORMATION = "START_UPDATE_USER_INFORMATION";
export const SUCCESS_UPDATE_USER_INFORMATION =
  "SUCCESS_UPDATE_USER_INFORMATION";
export const ERROR_UPDATE_USER_INFORMATION = "ERROR_UPDATE_USER_INFORMATION";

export const START_UPDATE_USER_PASSWORD = "START_UPDATE_USER_PASSWORD";
export const SUCCESS_UPDATE_USER_PASSWORD = "SUCCESS_UPDATE_USER_PASSWORD";
export const ERROR_UPDATE_USER_PASSWORD = "ERROR_UPDATE_USER_PASSWORD";

export const START_USER_FORGOT_PASSWORD = "START_USER_FORGOT_PASSWORD";
export const SUCCESS_USER_FORGOT_PASSWORD = "SUCCESS_USER_FORGOT_PASSWORD";
export const ERROR_USER_FORGOT_PASSWORD = "ERROR_USER_FORGOT_PASSWORD";

export const START_USER_RESET_PASSWORD = "START_USER_RESET_PASSWORD";
export const SUCCESS_USER_RESET_PASSWORD = "SUCCESS_USER_RESET_PASSWORD";
export const ERROR_USER_RESET_PASSWORD = "ERROR_USER_RESET_PASSWORD";

export const START_GET_PAGE_DETAILS = "START_GET_PAGE_DETAILS";
export const SUCCESS_GET_PAGE_DETAILS = "SUCCESS_GET_PAGE_DETAILS";
export const ERROR_GET_PAGE_DETAILS = "ERROR_GET_PAGE_DETAILS";

export const START_GET_USER_SETTINGS = "START_GET_USER_SETTINGS";
export const SUCCESS_GET_USER_SETTINGS = "SUCCESS_GET_USER_SETTINGS";
export const ERROR_GET_USER_SETTINGS = "ERROR_GET_USER_SETTINGS";

export const START_GET_ADMIN_VIDEO_LINK = "START_GET_ADMIN_VIDEO_LINK";
export const SUCCESS_GET_ADMIN_VIDEO_LINK = "SUCCESS_GET_ADMIN_VIDEO_LINK";
export const ERROR_GET_ADMIN_VIDEO_LINK = "ERROR_GET_ADMIN_VIDEO_LINK";

export const START_USER_VERIFY_OTP = "START_USER_VERIFY_OTP";
export const SUCCESS_USER_VERIFY_OTP = "SUCCESS_USER_VERIFY_OTP";
export const ERROR_USER_VERIFY_OTP = "ERROR_USER_VERIFY_OTP";

export const START_GET_ADS_BANNER_LIST = "START_GET_ADS_BANNER_LIST";
export const SUCCESS_GET_ADS_BANNER_LIST = "SUCCESS_GET_ADS_BANNER_LIST";
export const ERROR_GET_ADS_BANNER_LIST = "ERROR_GET_ADS_BANNER_LIST";
