import { useEffect, useRef, useState } from "react";
import CryptoJS from "crypto-js";
const crypto = require("crypto");

const algorithm = "aes-256-cbc";
let secretKey = "u-max-encrypt-161234567890-acbde";
let secretIV = "u-max-encrypt-16";

export const DiscountCalculation = (mrp, discount) => {
  let newprice = parseInt(mrp) - (parseInt(mrp) * parseInt(discount)) / 100;
  return Math.ceil(newprice).toFixed(2);
};

export const CartSumCalculation = (price, count) => {
  let newprice = parseInt(price) * parseInt(count).toFixed(0);
  return Math.ceil(newprice).toFixed(2);
};

export const encrypt = (text) => {
  // return text2;
  
  if (!isNaN(text)) {
    text = text.toString();
  }
  
  const secretIV = CryptoJS.enc.Utf8.parse("u-max-encrypt-16");
  const secretKey = CryptoJS.enc.Utf8.parse("u-max-encrypt-161234567890-acbde");
  
  const encrypted2 = CryptoJS.AES.encrypt(text, secretKey, { iv: secretIV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return encrypted2.ciphertext.toString(CryptoJS.enc.Hex);

};

export const decrypt = (content) => {
  const ciphertext = CryptoJS.enc.Hex.parse(content);
  const secretIV = CryptoJS.enc.Utf8.parse("u-max-encrypt-16");
  const secretKey = CryptoJS.enc.Utf8.parse("u-max-encrypt-161234567890-acbde");
  // const iv = enc.Utf8.parse("this is initial vector");
  const bytes = CryptoJS.AES.decrypt({ciphertext}, secretKey,{iv:secretIV,mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7});
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  // const decipher = crypto.createDecipheriv(algorithm, secretKey, secretIV);
  // const decrpyted = Buffer.concat([
  //   decipher.update(Buffer.from(content, "hex")),
  //   decipher.final(),
  // ]);
  
  return decryptedData
};

const replaceAll = (string, search, replace) => {
  if (string !== "") {
    return string.split(search).join(replace);
  }
};

export const replaceAllString = (string, search, replace) => {
  if (string !== "") {
    return string.split(search).join(replace);
  }
};

export const getTimeRemaining = (endtime) => {
  //const total = Date.parse(endtime) - Date.parse(new Date());
  const total = endtime - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};

export const dateFormatMDY = function (date) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let dateH = new Date(date);
  let day = dateH.getDate();
  let year = dateH.getUTCFullYear();
  let month = dateH.getMonth();

  return monthNames[month] + " " + day + ", " + year;
};

export const deliveryDateFormatMDY = function (date, delivery_day) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let dateH = new Date(date);
  dateH.setDate(dateH.getDate() + parseInt(delivery_day));
  let day = dateH.getDate();
  let year = dateH.getUTCFullYear();
  let month = dateH.getMonth();

  return monthNames[month] + " " + day + ", " + year;
};

export const orderCancelStatus = function (date, dayCount) {
  let orderDate = new Date(date);
  orderDate.setDate(orderDate.getDate() + dayCount);
  let today = new Date();

  if (today >= orderDate) {
    return 1;
  }
  return 0;
};

export const pageNameWithReplace = function (pageTitle) {
  if (pageTitle) {
    let newpageTitle = pageTitle;
    newpageTitle = replaceAll(newpageTitle, "-", " ");
    newpageTitle = replaceAll(newpageTitle, "/", "");
    return newpageTitle;
  } else {
    return pageTitle;
  }
};

export const getOrderCalculations = (cartListData = [], packingCharge = 0, deliveryCharge = 0) => {
  let finalAmount = 0;
  let tmpproductTotalMrpPrice = 0;
  let tmpproductTotalPrice = 0;
  let tmpDeliveryCharges = 0;
  let tmpPackingChange = 0;
  cartListData.map((product, key) => {
    tmpproductTotalMrpPrice = parseInt(product.product.mrp_price) * parseInt(product.order_quantity) + tmpproductTotalMrpPrice;
    tmpproductTotalPrice = parseInt(product.product.display_price) * parseInt(product.order_quantity) + tmpproductTotalPrice;
  });
  tmpDeliveryCharges = (tmpproductTotalPrice * parseInt(deliveryCharge)) / 100;
  tmpPackingChange = (tmpproductTotalPrice * parseInt(packingCharge)) / 100;
  finalAmount = tmpproductTotalPrice + tmpDeliveryCharges + tmpPackingChange;

  return {
    tProductTotalPrice: tmpproductTotalPrice,
    tProductTotalDiscount: (tmpproductTotalMrpPrice - tmpproductTotalPrice),
    tProductTotalAmount: finalAmount,
    tDeliveryCharges: tmpDeliveryCharges,
    tPackingChanges: tmpPackingChange,
  };
};
