import React, { useState } from "react";
import { makeStyles, Grid, Paper } from "@material-ui/core";
import clsx from "clsx";
import FeaturedVideoIcon from "@material-ui/icons/FeaturedVideo";
import RedeemIcon from "@material-ui/icons/Redeem";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  rootFooter: {
    flexGrow: 0,
    marginTop: 10,
    "&>hr": {
      margin: "20px 0px",
    },
  },
  footerLink: {
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
    textAlign: "left",
    paddingLeft: "4%",
    "&>div": {
      color: "#878787",
      fontSize: 12,
      fontWeight: 400,
      marginBottom: 9,
    },
    "&>a": {
      lineHeight: 2,
      color: "#172337",
      display: "block",
      fontWeight: 400,
      fontSize: 12,
      textDecoration: "none",
    },
  },
  bottomFooter: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2% 0%",
    textAlign: "left",
    "&>div": {
      display: "flex",
      alignItems: "center",
      paddingLeft: "4%",
      cursor: "pointer",
      "&>svg": {
        width: 20,
      },
      "&>img": {
        width: 195,
        [theme.breakpoints.down("xs")]: {
          margin: '15px auto 5px auto',
          width: '50%'
        }
      },
    },
  },
}));

export default function Footer(props) {
  const classes = useStyles();
  const {} = props;

  return (
    <div className={clsx(classes.rootFooter)}>
      <hr />
      <Grid container>
        <Grid item xs={6} sm={3} className={classes.footerLink}>
          <div>ABOUT</div>
          <Link to="/contact-us">Contact Us</Link>
          <Link to="/about-us">About Us</Link>
          <Link to="/faq">FAQ</Link>
        </Grid>
        <Grid item xs={6} sm={3} className={classes.footerLink}>
          <div>HELP</div>
          <Link to="/payments">Payments</Link>
          <Link to="/shipping">Shipping</Link>
          <Link to="/cancellation-and-returns">Cancellation &amp; Returns</Link>
        </Grid>
        <Grid item xs={6} sm={3} className={classes.footerLink}>
          <div>POLICY</div>
          <Link to="/return-policy">Return Policy</Link>
          <Link to="/terms-of-use">Terms Of Use</Link>
          <Link to="/security">Security</Link>
          <Link to="/privacy">Privacy</Link>
        </Grid>
        <Grid item xs={6} sm={3} className={classes.footerLink}>
          <div>SOCIAL</div>
          <a href="https://www.facebook.com/Maxkitchenwarestore" target="blank">
            Facebook
          </a>
          <a href="https://www.twitter.com/" target="blank">
            Twitter
          </a>
          <a
            href="https://www.youtube.com/channel/UCd_v5N4gq2oPEeWj4sqFm1Q/videos"
            target="blank"
          >
            YouTube
          </a>
        </Grid>
      </Grid>
      <Grid container className={classes.bottomFooter}>
        <Grid item xs={4} sm={3}>
          <FeaturedVideoIcon /> &nbsp; Advertise
        </Grid>
        <Grid item xs={4} sm={3}>
          {" "}
          <RedeemIcon /> &nbsp; Gift Cards
        </Grid>
        <Grid item xs={4} sm={3}>
          © 2021 mitoxi.in
        </Grid>
        <Grid item xs={12} sm={3}>
          <img src={`${process.env.PUBLIC_URL}/image/payment.png`} />
        </Grid>
      </Grid>
    </div>
  );
}
