import logo from "./logo.svg";
import "./App.css";
import Main from "./Main.js";
import { BrowserRouter } from "react-router-dom";
import { theme } from "./theme.js";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import store from './store';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
       
          <div className="App">
            <Main />
          </div>
        
      </ThemeProvider>
    </Provider>
  );
}

export default App;
