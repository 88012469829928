import {
  call,
  put,
  take,
  fork,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import {
  START_USER_SIGN_UP,
  SUCCESS_USER_SIGN_UP,
  ERROR_USER_SIGN_UP,
  START_USER_SIGN_IN,
  SUCCESS_USER_SIGN_IN,
  ERROR_USER_SIGN_IN,
  START_GET_USER_DETAILS,
  SUCCESS_GET_USER_DETAILS,
  ERROR_GET_USER_DETAILS,
  START_GET_BANNER_LIST,
  SUCCESS_GET_BANNER_LIST,
  ERROR_GET_BANNER_LIST,
  START_USER_ADDRESS_ACTION,
  SUCCESS_USER_ADDRESS_ACTION,
  ERROR_USER_ADDRESS_ACTION,
  START_UPDATE_USER_INFORMATION,
  SUCCESS_UPDATE_USER_INFORMATION,
  ERROR_UPDATE_USER_INFORMATION,
  START_UPDATE_USER_PASSWORD,
  SUCCESS_UPDATE_USER_PASSWORD,
  ERROR_UPDATE_USER_PASSWORD,
  START_USER_FORGOT_PASSWORD,
  SUCCESS_USER_FORGOT_PASSWORD,
  ERROR_USER_FORGOT_PASSWORD,
  START_USER_RESET_PASSWORD,
  SUCCESS_USER_RESET_PASSWORD,
  ERROR_USER_RESET_PASSWORD,
  START_GET_SUB_BANNER_LIST,
  SUCCESS_GET_SUB_BANNER_LIST,
  ERROR_GET_SUB_BANNER_LIST,
  START_GET_PAGE_DETAILS,
  SUCCESS_GET_PAGE_DETAILS,
  ERROR_GET_PAGE_DETAILS,
  START_GET_USER_SETTINGS,
  SUCCESS_GET_USER_SETTINGS,
  ERROR_GET_USER_SETTINGS,
  START_GET_ADMIN_VIDEO_LINK,
  SUCCESS_GET_ADMIN_VIDEO_LINK,
  ERROR_GET_ADMIN_VIDEO_LINK,
  START_USER_VERIFY_OTP,
  SUCCESS_USER_VERIFY_OTP,
  ERROR_USER_VERIFY_OTP,
  START_GET_ADS_BANNER_LIST,
  SUCCESS_GET_ADS_BANNER_LIST,
  ERROR_GET_ADS_BANNER_LIST
} from "../actions/user";

import {
  userSignUpAPI,
  userSignInAPI,
  getUserDetailsAPI,
  getBannerAPI,
  userAddressActionAPI,
  updateProfileInformationAPI,
  updateUserPasswordAPI,
  userForgotPasswordAPI,
  userResetPasswordAPI,
  getSubBannerListAPI,
  getPageDetailsAPI,
  userSettingsAPI,
  getAdminVideoLinkAPI,
  userVerifyOTPAPI,
  getAdsBannerListAPI
} from "../api/user";

//START: Start SignUp
function* userSignUpWorker({ data }) {
  const response = yield call(userSignUpAPI, data);
  try {
    if (response.status) {
      window.localStorage.setItem("token", response.token);
      const userDetailsResponse = yield call(getUserDetailsAPI);
      if (userDetailsResponse.status) {
        let cartCount = userDetailsResponse?.data?.cart_count
          ? userDetailsResponse?.data?.cart_count
          : 0;
        window.localStorage.setItem("cartCount", cartCount);
        yield put({
          type: SUCCESS_USER_SIGN_UP,
          userDetails: userDetailsResponse.data,
          message: response.message || "Something went wrong, try again later!",
        });
      }
    } else {
      yield put({
        type: ERROR_USER_SIGN_UP,
        userDetails: "",
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_USER_SIGN_UP,
      userDetails: "",
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start SignIn
function* userSignInWorker({ data }) {
  const response = yield call(userSignInAPI, data);
  try {
    if (response.status) {
      window.localStorage.setItem("token", response.token);
      const userDetailsResponse = yield call(getUserDetailsAPI);
      if (userDetailsResponse.status) {
        let cartCount = userDetailsResponse?.data?.cart_count
          ? userDetailsResponse?.data?.cart_count
          : 0;
        window.localStorage.setItem("cartCount", cartCount);
        yield put({
          type: SUCCESS_USER_SIGN_IN,
          userDetails: userDetailsResponse.data,
          message: response.message || "Something went wrong, try again later!",
        });
      }
    } else {
      yield put({
        type: ERROR_USER_SIGN_IN,
        userDetails: "",
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_USER_SIGN_IN,
      userDetails: "",
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start User Details
function* getUserDetailsWorker({ data }) {
  const response = yield call(getUserDetailsAPI, data);
  try {
    if (response.status) {
      let cartCount = response?.data?.cart_count
        ? response?.data?.cart_count
        : 0;
      window.localStorage.setItem("cartCount", cartCount);
      yield put({
        type: SUCCESS_GET_USER_DETAILS,
        userDetails: response.data,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_USER_DETAILS,
        userDetails: "",
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_USER_DETAILS,
      userDetails: "",
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start Banner
function* getBannerWorker({ data }) {
  const response = yield call(getBannerAPI, data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_BANNER_LIST,
        banner: response.banner,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_BANNER_LIST,
        banner: [],
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_BANNER_LIST,
      banner: [],
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start User Address Actions
function* userAddressActionWorker({ address, addressId, actionType }) {
  const response = yield call(
    userAddressActionAPI,
    address,
    addressId,
    actionType
  );
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_USER_ADDRESS_ACTION,
        address_id: addressId,
        action_type: actionType,
        address_list: response.result,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_USER_ADDRESS_ACTION,
        address_id: addressId,
        action_type: actionType,
        address_list: "",
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_USER_ADDRESS_ACTION,
      address_id: addressId,
      action_type: actionType,
      address_list: "",
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start update user information
function* updateProfileInformationWorker({ data }) {
  const response = yield call(updateProfileInformationAPI, data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_UPDATE_USER_INFORMATION,
        user_details: response.data,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_UPDATE_USER_INFORMATION,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_UPDATE_USER_INFORMATION,
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start update user password
function* updateUserPasswordWorker({ data }) {
  const response = yield call(updateUserPasswordAPI, data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_UPDATE_USER_PASSWORD,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_UPDATE_USER_PASSWORD,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_UPDATE_USER_PASSWORD,
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start user password reset
function* userForgotPasswordWorker({ email, actionType }) {
  const response = yield call(userForgotPasswordAPI, email, actionType);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_USER_FORGOT_PASSWORD,
        actionType: actionType,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_USER_FORGOT_PASSWORD,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_USER_FORGOT_PASSWORD,
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start user password reset
function* userResetPasswordWorker({ data, resetToken }) {
  const response = yield call(userResetPasswordAPI, data, resetToken);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_USER_RESET_PASSWORD,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_USER_RESET_PASSWORD,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_USER_RESET_PASSWORD,
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start user password reset
function* getSubBannerListWorker({ data, resetToken }) {
  const response = yield call(getSubBannerListAPI, data, resetToken);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_SUB_BANNER_LIST,
        sub_banner: response.sub_banner,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_SUB_BANNER_LIST,
        sub_banner: [],
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_SUB_BANNER_LIST,
      sub_banner: [],
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start get page details
function* getPageDetailsWorker({ pageName }) {
  const response = yield call(getPageDetailsAPI, pageName);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_PAGE_DETAILS,
        pageDetails: response.data,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_PAGE_DETAILS,
        pageDetails: "",
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_PAGE_DETAILS,
      pageDetails: "",
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start get user setting details
function* userSettingsWorker({ pageName }) {
  const response = yield call(userSettingsAPI, pageName);
  
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_USER_SETTINGS,
        user_settings: response.data,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_USER_SETTINGS,
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_USER_SETTINGS,
    });
  }
}

//START: Start get user setting details
function* getAdminVideoLinkWorker() {
  const response = yield call(getAdminVideoLinkAPI);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_ADMIN_VIDEO_LINK,
        videoData: response.data,
      });
    } else {
      yield put({
        type: ERROR_GET_ADMIN_VIDEO_LINK,
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_ADMIN_VIDEO_LINK,
    });
  }
}

//START: Start get user setting details
function* userVerifyOTPWorker({ email, otpValue }) {
  const response = yield call(userVerifyOTPAPI, email, otpValue);
  try {
    if (response.status) {
      window.localStorage.setItem("token", response.token);
      const userDetailsResponse = yield call(getUserDetailsAPI);
      if (userDetailsResponse.status) {
        let cartCount = userDetailsResponse?.data?.cart_count
          ? userDetailsResponse?.data?.cart_count
          : 0;
        window.localStorage.setItem("cartCount", cartCount);
        yield put({
          type: SUCCESS_USER_VERIFY_OTP,
          userDetails: userDetailsResponse.data,
          message: response.message || "Something went wrong, try again later!",
        });
      }
    } else {
      yield put({
        type: ERROR_USER_VERIFY_OTP,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_USER_VERIFY_OTP,
    });
  }
}

//START: Start Ads banner
function* getAdsBannerListWorker({ data }) {
  const response = yield call(getAdsBannerListAPI, data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_ADS_BANNER_LIST,
        ads_banner: response.ads_banner,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_ADS_BANNER_LIST,
        ads_banner: [],
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_ADS_BANNER_LIST,
      ads_banner: [],
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

export function* userWatcher() {
  yield takeLatest(START_USER_SIGN_UP, userSignUpWorker);
  yield takeLatest(START_USER_SIGN_IN, userSignInWorker);
  yield takeLatest(START_GET_USER_DETAILS, getUserDetailsWorker);
  yield takeLatest(START_GET_BANNER_LIST, getBannerWorker);
  yield takeLatest(START_USER_ADDRESS_ACTION, userAddressActionWorker);
  yield takeLatest(
    START_UPDATE_USER_INFORMATION,
    updateProfileInformationWorker
  );
  yield takeLatest(START_UPDATE_USER_PASSWORD, updateUserPasswordWorker);
  yield takeLatest(START_USER_FORGOT_PASSWORD, userForgotPasswordWorker);
  yield takeLatest(START_USER_RESET_PASSWORD, userResetPasswordWorker);
  yield takeLatest(START_GET_SUB_BANNER_LIST, getSubBannerListWorker);
  yield takeEvery(START_GET_PAGE_DETAILS, getPageDetailsWorker);
  yield takeEvery(START_GET_USER_SETTINGS, userSettingsWorker);
  yield takeEvery(START_GET_ADMIN_VIDEO_LINK, getAdminVideoLinkWorker);
  yield takeEvery(START_USER_VERIFY_OTP, userVerifyOTPWorker);
  yield takeLatest(START_GET_ADS_BANNER_LIST, getAdsBannerListWorker);
}
