import API from "../api";
import store from '../store';

export function getProductListAPI(data, typeList) {
  const state = store.getState();
  console.log(state,"this is state")
  const productType = state?.product?.productTypeOfList ?? "";
  if (typeList == "list" || typeList == "more") {
    let endPoint = "product";
    if (productType == 'best'){
      endPoint = "product/best-seling";
    }else if(productType == 'day'){
      endPoint = "product/deals_of_the_day";
    }else if(productType == 'new-arrival'){
      endPoint = "product/new-arrival";
    }
    let api = `/${endPoint}?offset=${data.offset}&category_slug=${data.category_slug}&sub_category_slug=${data.sub_category_slug}&child_category_slug=${data.child_category_slug}&min_price=${data.min_price}&max_price=${data.max_price}&brand_id=${data.brand_id}&discount=${data.discount}`
    
    const array = ["is_day_of_deal","is_best_selling","is_imported_gadgets","is_new_arrival","is_small_gadgets"]
    array.forEach(element => {
      if(data[element]){
        api+=`&${element}=true`
      }
    });
    return API.get(api);
  } else {
    return API.get(`/product`);
  }
}

export function getGalleryListAPI(galleryOffset) {
    return API.get(`/product/gallery?offset=${galleryOffset}&limit=100`);
}

export function getCategoryListAPI() {
  return API.get("/product/category/list");
}

export function getBrandListAPI() {
  return API.get("/product/brand/list");
}

export function getProductDetailsAPI(productId) {
  return API.get(`product/${productId}`);
}

export function productAddToCartAPI(data) {
  return API.post(`cart`, data);
}

export function getCartListAPI() {
  return API.get(`cart`);
}

export function cartProductCountUpdateAPI(cartId, productCount) {
  return API.put(`cart/${cartId}`, { order_quantity: productCount });
}

export function removeProductToCartAPI(cartId) {
  return API.deleteM(`cart/${cartId}`);
}

export function productAddToWishListAPI(productId, productOffset, actionType) {
  if (actionType == "post") {
    return API.post(`product/wish-list`, { product_id: productId });
  }
  if (actionType == "get") {
    return API.get(`product/wish-list?offset=${productOffset}`);
  }

  if (actionType == "delete") {
    return API.deleteM(`product/wish-list/${productId}`);
  }
}

export function getProductRatingListAPI(product_id) {
  return API.get(`product/product-review//${product_id}`);
}

export function userOrderAPI(data, actionType) {
  if (actionType == "post") {
    return API.post(`order`, data);
  }
  if (actionType == "get") {
    return API.get(`order`);
  }

  if (actionType == "cancel") {
    return API.get(`order/order-cancel/${data}`);
  }
  if (actionType == "return") {
    return API.get(`order/order-return/${data}`);
  }
}

export function productRatingListAPI(data) {
  return API.post(`product/product-review`, data);
}

export function getBestSellingProductListAPI(productType) {
  if (productType == "best") {
    return API.get(`product/best-seling`);
  }
  if (productType == "day") {
    return API.get(`product/deals-of-the-day`);
  }

  if (productType == "new-arrival") {
    return API.get(`product/new-arrival`);
  }

  if (productType == "small") {
    return API.get(`product/small-gadgets`);
  }

  if (productType == "imported") {
    return API.get(`product/imported-gadgets`);
  }
}

export function searchProductAPI(productName) {
  return API.get(`/product/search?searchQuery=${productName}`);
}

export function productBulkInquiryAPI(data) {
  return API.post(`product/bulk-inquiry`, data?.data);
}

export function getStateChargesAPI() {
  return API.get(`profile/state-charge`);
}
