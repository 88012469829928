import API from "../api";

export function userSignUpAPI(data) {
  return API.post("/sign-up", data);
}

export function userSignInAPI(data) {
  return API.post("/login", data);
}

export function getUserDetailsAPI() {
  return API.get("/profile/me");
}

export function getBannerAPI() {
  return API.get("/banner");
}

export function userAddressActionAPI(address, addressId, actionType) {
  if (actionType == "get") {
    return API.get("/order/user/address");
  }
  if (actionType == "put") {
    return API.put(`/order/user-address/${addressId}`, address);
  }
  if (actionType == "post") {
    return API.post("/order/user-address", address);
  }
  if (actionType == "delete") {
    return API.deleteM(`/order/user-address/${addressId}`);
  }
}

export function updateProfileInformationAPI(data) {
  return API.put("/profile", data);
}
export function updateUserPasswordAPI(data) {
  return API.post("/auth/change-password", data);
}

export function userForgotPasswordAPI(email, actionType) {
  if (actionType == "otp") {
    return API.post("/auth/otp-send", { m_number: email });
  }
  if (actionType == "forgot") {
    return API.post("/auth/recover-password", { email: email });
  }
}

export function userResetPasswordAPI(data, resetToken) {
  return API.post(`auth/update-password/${resetToken}`, data);
}

export function getSubBannerListAPI() {
  return API.get("/sub-banner");
}

export function getPageDetailsAPI(pageName) {
  if (pageName == "cancellation-and-returns") {
    return API.get(`/page/cancellation-returns`);
  } else {
    return API.get(`/page/${pageName}`);
  }
}

export function userSettingsAPI() {
  return API.get("/page/setting");
}

export function getAdminVideoLinkAPI() {
  return API.get("/video");
}

function getOrderPaymentRazorpay(data) {
  return API.post("/order/payment/razorpay", data);
}

export function userVerifyOTPAPI(email, otpValue) {
  return API.post("/auth/otp-verification", {
    m_number: email,
    otp_verification: otpValue,
  });
}

export function getAdsBannerListAPI() {
  return API.get("/ads-banner");
}
