import {
  call,
  put,
  take,
  fork,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";

import {
  START_GET_PRODUCT_LIST,
  SUCCESS_GET_PRODUCT_LIST,
  ERROR_GET_PRODUCT_LIST,
  START_GET_GALLERY_LIST,
  SUCCESS_GET_GALLERY_LIST,
  ERROR_GET_GALLERY_LIST,
  START_GET_CATEGORY_LIST,
  SUCCESS_GET_CATEGORY_LIST,
  ERROR_GET_CATEGORY_LIST,
  START_GET_BRAND_LIST,
  SUCCESS_GET_BRAND_LIST,
  ERROR_GET_BRAND_LIST,
  START_GET_PRODUCT_DETAILS,
  SUCCESS_GET_PRODUCT_DETAILS,
  ERROR_GET_PRODUCT_DETAILS,
  START_PRODUCT_ADD_TO_CART,
  SUCCESS_PRODUCT_ADD_TO_CART,
  ERROR_PRODUCT_ADD_TO_CART,
  START_GET_CART_LIST,
  SUCCESS_GET_CART_LIST,
  ERROR_GET_CART_LIST,
  START_CART_PRODUCT_COUNT_UPDATE,
  SUCCESS_CART_PRODUCT_COUNT_UPDATE,
  ERROR_CART_PRODUCT_COUNT_UPDATE,
  START_PRODUCT_REMOVE_TO_CART,
  SUCCESS_PRODUCT_REMOVE_TO_CART,
  ERROR_PRODUCT_REMOVE_TO_CART,
  START_PRODUCT_ADD_TO_WISHLIST,
  SUCCESS_PRODUCT_ADD_TO_WISHLIST,
  ERROR_PRODUCT_ADD_TO_WISHLIST,
  START_GET_BEST_SELLING_PRODUCT_LIST,
  SUCCESS_GET_BEST_SELLING_PRODUCT_LIST,
  ERROR_GET_BEST_SELLING_PRODUCT_LIST,
  START_GET_PRODUCT_RATING_LIST,
  SUCCESS_GET_PRODUCT_RATING_LIST,
  ERROR_GET_PRODUCT_RATING_LIST,
  START_USER_ORDER,
  SUCCESS_USER_ORDER,
  ERROR_USER_ORDER,
  START_USER_PRODUCT_REVIEW,
  SUCCESS_USER_PRODUCT_REVIEW,
  ERROR_USER_PRODUCT_REVIEW,
  START_GET_SEARCH_PRODUCT_LIST,
  SUCCESS_GET_SEARCH_PRODUCT_LIST,
  ERROR_GET_SEARCH_PRODUCT_LIST,
  START_BULK_INQUIRY,
  SUCCESS_BULK_INQUIRY,
  ERROR_BULK_INQUIRY,
  SET_PRODUCT_TYPE_OF_LIST,
  START_GET_STATE_CHARGES_LIST,
  SUCCESS_GET_STATE_CHARGES_LIST,
  ERROR_GET_STATE_CHARGES_LIST,
  START_GET_SMALL_GADGETS_PRODUCT_LIST,
  START_GET_IMPORTED_GADGETS_PRODUCT_LIST
} from "../actions/product";

import {
  getProductListAPI,
  getCategoryListAPI,
  getBrandListAPI,
  getProductDetailsAPI,
  productAddToCartAPI,
  getCartListAPI,
  cartProductCountUpdateAPI,
  removeProductToCartAPI,
  productAddToWishListAPI,
  getBestSellingProductListAPI,
  getProductRatingListAPI,
  userOrderAPI,
  productRatingListAPI,
  searchProductAPI,
  getGalleryListAPI,
  productBulkInquiryAPI,
  getStateChargesAPI
} from "../api/product";

//START: Start get product list
function* getProductListWorker({ data, typeList }) {
  const response = yield call(getProductListAPI, data, typeList);
  
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_PRODUCT_LIST,
        product_list: response.data,
        product_count:
          typeList == "list" || typeList == "more" ? response.result_count : 0,
        type_list: typeList,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_PRODUCT_LIST,
        product_list: [],
        type_list: typeList,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_PRODUCT_LIST,
      product_list: [],
      type_list: typeList,
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start get category list
function* getCategoryListWorker() {
  const response = yield call(getCategoryListAPI);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_CATEGORY_LIST,
        category_list: response.data,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_CATEGORY_LIST,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_CATEGORY_LIST,
      like_data: "",
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start get brand list
function* getBrandListWorker() {
  const response = yield call(getBrandListAPI);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_BRAND_LIST,
        brand_list: response.data,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_BRAND_LIST,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_BRAND_LIST,
      like_data: "",
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start get product Details
function* getProductDetailsWorker({ productId }) {
  const response = yield call(getProductDetailsAPI, productId);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_PRODUCT_DETAILS,
        product_details: response.data,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_PRODUCT_DETAILS,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_PRODUCT_DETAILS,
      like_data: "",
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start product add to cart
function* productAddToCartWorker({ data }) {
  const response = yield call(productAddToCartAPI, data);
  try {
    if (response.status) {
      const cartResponse = yield call(getCartListAPI, data);
      if (cartResponse.status) {
        window.localStorage.setItem("cartCount", response?.cart_count);
        yield put({
          type: SUCCESS_PRODUCT_ADD_TO_CART,
          cartCount: response?.cart_count,
          cart_list_data: cartResponse?.cart,
          message: response.message || "Something went wrong, try again later!",
        });
      }
    } else {
      yield put({
        type: ERROR_PRODUCT_ADD_TO_CART,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_PRODUCT_ADD_TO_CART,
      like_data: "",
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start get cart list
function* getCartListWorker({ data }) {
  const response = yield call(getCartListAPI, data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_CART_LIST,
        list_data: response?.cart,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_CART_LIST,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_CART_LIST,
      like_data: "",
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start cart Update
function* cartProductCountUpdateWorker({ cartId, productCount }) {
  const response = yield call(cartProductCountUpdateAPI, cartId, productCount);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_CART_PRODUCT_COUNT_UPDATE,
        cartId: cartId,
        productCount: productCount,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_CART_PRODUCT_COUNT_UPDATE,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_CART_PRODUCT_COUNT_UPDATE,
      like_data: "",
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start product remove to cart
function* removeProductToCartWorker({ cartId }) {
  const response = yield call(removeProductToCartAPI, cartId);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_PRODUCT_REMOVE_TO_CART,
        cartId: cartId,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_PRODUCT_REMOVE_TO_CART,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_PRODUCT_REMOVE_TO_CART,
      cartId: cartId,
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start product add to wish list
function* productAddToWishListWorker({ productId, productOffset, actionType }) {
  const response = yield call(
    productAddToWishListAPI,
    productId,
    productOffset,
    actionType
  );
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_PRODUCT_ADD_TO_WISHLIST,
        productId: productId,
        action_type: actionType,
        product_wishlist: actionType == "get" ? response?.data : [],
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_PRODUCT_ADD_TO_WISHLIST,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_PRODUCT_ADD_TO_WISHLIST,
      productId: productId,
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start product add to wish list
function* getBestSellingProductListWorker({ productType }) {
  const response = yield call(getBestSellingProductListAPI, productType);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_BEST_SELLING_PRODUCT_LIST,
        product_list: response.data,
        product_type: productType,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_BEST_SELLING_PRODUCT_LIST,
        product_type: productType,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_BEST_SELLING_PRODUCT_LIST,
      product_list: [],
      product_type: productType,
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start product add to wish list
function* getProductRatingListWorker({ productId, actionType }) {
  const response = yield call(getProductRatingListAPI, productId);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_PRODUCT_RATING_LIST,
        product_rating_list: response.data,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_PRODUCT_RATING_LIST,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_PRODUCT_RATING_LIST,
      product_rating_list: [],
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start user order
function* userOrderWorker({ data, actionType }) {
  const response = yield call(userOrderAPI, data, actionType);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_USER_ORDER,
        product_rating_list: actionType == "get" ? response.data : [],
        action_type: actionType,
        order_id: data,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_USER_ORDER,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_USER_ORDER,
      product_rating_list: [],
      action_type: actionType,
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start user order
function* userProductReviewWorker({ data }) {
  const response = yield call(productRatingListAPI, data);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_USER_PRODUCT_REVIEW,
        product_id: data.product_id,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_USER_PRODUCT_REVIEW,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_USER_PRODUCT_REVIEW,
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start product add to wish list
function* getSearchProductListWorker({ productName }) {
  const response = yield call(searchProductAPI, productName);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_SEARCH_PRODUCT_LIST,
        product_list: response.data,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_SEARCH_PRODUCT_LIST,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_SEARCH_PRODUCT_LIST,
      product_list: [],
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start get product list
function* getGalleryListWorker({galleryOffset, actionType}) {
  const response = yield call(getGalleryListAPI, galleryOffset);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_GALLERY_LIST,
        gallery_list: response.data,
        result_count: response.result_count,
        type_list: actionType,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_GET_GALLERY_LIST,
        gallery_list: [],
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_GET_GALLERY_LIST,
      gallery_list: [],
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start product bulk inquiry
function* productBulkInquiryWorker(data) {
  const response = yield call(
    productBulkInquiryAPI,
    data
  );
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_BULK_INQUIRY,
        message: response.message || "Something went wrong, try again later!",
      });
    } else {
      yield put({
        type: ERROR_BULK_INQUIRY,
        message: response.message || "Something went wrong, try again later!",
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_BULK_INQUIRY,
      message: response.message || "Something went wrong, try again later!",
    });
  }
}

//START: Start get state charges
function* getStateChargesWorker() {
  const response = yield call(getStateChargesAPI);
  try {
    if (response.status) {
      yield put({
        type: SUCCESS_GET_STATE_CHARGES_LIST,
        state_charges: response?.state_charges ?? [],
      });
    } else {
      yield put({
        type: ERROR_GET_STATE_CHARGES_LIST
      });
    }
  } catch (e) {
    yield put({
      type: ERROR_BULK_INQUIRY
    });
  }
}

export function* getProductWatcher() {
  yield takeLatest(START_GET_PRODUCT_LIST, getProductListWorker);
  yield takeLatest(START_GET_GALLERY_LIST, getGalleryListWorker);
  yield takeLatest(START_GET_CATEGORY_LIST, getCategoryListWorker);
  yield takeLatest(START_GET_BRAND_LIST, getBrandListWorker);
  yield takeLatest(START_GET_PRODUCT_DETAILS, getProductDetailsWorker);
  yield takeLatest(START_PRODUCT_ADD_TO_CART, productAddToCartWorker);
  yield takeLatest(START_GET_CART_LIST, getCartListWorker);
  yield takeLatest(START_CART_PRODUCT_COUNT_UPDATE,cartProductCountUpdateWorker);
  yield takeLatest(START_PRODUCT_REMOVE_TO_CART, removeProductToCartWorker);
  yield takeLatest(START_PRODUCT_ADD_TO_WISHLIST, productAddToWishListWorker);

  yield takeEvery(START_GET_BEST_SELLING_PRODUCT_LIST,getBestSellingProductListWorker);
  


  



  yield takeLatest(START_GET_PRODUCT_RATING_LIST, getProductRatingListWorker);
  yield takeLatest(START_USER_ORDER, userOrderWorker);
  yield takeLatest(START_USER_PRODUCT_REVIEW, userProductReviewWorker);
  yield takeEvery(START_GET_SEARCH_PRODUCT_LIST, getSearchProductListWorker);
  yield takeLatest(START_BULK_INQUIRY, productBulkInquiryWorker);
  yield takeLatest(START_GET_STATE_CHARGES_LIST, getStateChargesWorker);
}
