import {
  EMPTY_PRODUCT_INIT_STATE,
  START_GET_PRODUCT_LIST,
  SUCCESS_GET_PRODUCT_LIST,
  ERROR_GET_PRODUCT_LIST,
  START_GET_GALLERY_LIST,
  SUCCESS_GET_GALLERY_LIST,
  ERROR_GET_GALLERY_LIST,
  START_GET_CATEGORY_LIST,
  SUCCESS_GET_CATEGORY_LIST,
  ERROR_GET_CATEGORY_LIST,
  START_GET_BRAND_LIST,
  SUCCESS_GET_BRAND_LIST,
  ERROR_GET_BRAND_LIST,
  START_GET_PRODUCT_DETAILS,
  SUCCESS_GET_PRODUCT_DETAILS,
  ERROR_GET_PRODUCT_DETAILS,
  START_PRODUCT_ADD_TO_CART,
  SUCCESS_PRODUCT_ADD_TO_CART,
  ERROR_PRODUCT_ADD_TO_CART,
  START_GET_CART_LIST,
  SUCCESS_GET_CART_LIST,
  ERROR_GET_CART_LIST,
  START_CART_PRODUCT_COUNT_UPDATE,
  SUCCESS_CART_PRODUCT_COUNT_UPDATE,
  ERROR_CART_PRODUCT_COUNT_UPDATE,
  START_PRODUCT_REMOVE_TO_CART,
  SUCCESS_PRODUCT_REMOVE_TO_CART,
  ERROR_PRODUCT_REMOVE_TO_CART,
  START_PRODUCT_ADD_TO_WISHLIST,
  SUCCESS_PRODUCT_ADD_TO_WISHLIST,
  ERROR_PRODUCT_ADD_TO_WISHLIST,
  START_GET_BEST_SELLING_PRODUCT_LIST,
  SUCCESS_GET_BEST_SELLING_PRODUCT_LIST,
  ERROR_GET_BEST_SELLING_PRODUCT_LIST,
  START_GET_PRODUCT_RATING_LIST,
  SUCCESS_GET_PRODUCT_RATING_LIST,
  ERROR_GET_PRODUCT_RATING_LIST,
  START_USER_ORDER,
  SUCCESS_USER_ORDER,
  ERROR_USER_ORDER,
  START_USER_PRODUCT_REVIEW,
  SUCCESS_USER_PRODUCT_REVIEW,
  ERROR_USER_PRODUCT_REVIEW,
  START_GET_SEARCH_PRODUCT_LIST,
  SUCCESS_GET_SEARCH_PRODUCT_LIST,
  ERROR_GET_SEARCH_PRODUCT_LIST,
  START_BULK_INQUIRY,
  SUCCESS_BULK_INQUIRY,
  ERROR_BULK_INQUIRY,
  SET_PRODUCT_TYPE_OF_LIST,
  START_GET_STATE_CHARGES_LIST,
  SUCCESS_GET_STATE_CHARGES_LIST,
  ERROR_GET_STATE_CHARGES_LIST
} from "../actions/product";

import { decrypt } from "../utils/customHooks";

const initState = {
  productListIsLoading: false,
  productListErrorMessage: "",
  productListSuccessMessage: "",
  productCategoryListData: [],
  productListData: [],
  productCount: 0,
  galleryListIsLoading: true,
  galleryCount: 0,
  galleryListErrorMessage: "",
  galleryListData: [],
  brandIsLoading: false,
  productDetailsIsLoading: false,
  productDetailsErrorMessage: "",
  productDetailsSuccessMessage: "",
  productDetailsData: "",
  productAddToCartIsLoading: false,
  productCartCount: "",
  cartIsLoading: false,
  cartErrorMessage: "",
  cartSuccessMessage: "",
  cartListData: [],
  cartUpdateIsLoading: false,
  initBrandValue: "",
  initCustomerRatings: "",
  initDiscount: "",
  initMinPrice: "",
  initMaxPrice: "",
  wishlistIsLoading: false,
  wishlistData: [],
  bestSellingProductIsLoading: false,
  bestSellingProductList: [],
  dealsOfDayProductList: [],
  newArrivalProductsList: [],

  smallGadgetsProductList:[],
  importedGadgetsProductList:[],


  productRatingIsLoading: false,
  productRatingList: [],
  userOrderIsLoading: false,
  userOrderList: [],
  userOrderSent: false,
  OrderErrorMessage: "",
  OrderSuccessMessage: "",
  productReviewIsLoading: false,
  productReviewSent: false,
  productReviewSuccessMessage: "",
  productReviewErrorMessage: "",
  searchProductIsLoading: false,
  searchProductList: [],
  bulkInquiryIsLoading: false,
  bulkInquirySuccessMessage: "",
  bulkInquiryErrorMessage: "",
  productTypeOfList: "",
  stateCharges: [],
};

const myProfile = (previousState, action) => {
  switch (action.type) {
    case EMPTY_PRODUCT_INIT_STATE:
      return {
        ...previousState,
        [action.key]: action.value,
      };
    case START_GET_PRODUCT_LIST:
      return {
        ...previousState,
        productListIsLoading: true,
        productListErrorMessage: "",
      };
    case SUCCESS_GET_PRODUCT_LIST:
      return {
        ...previousState,
        productListIsLoading: false,
        productCount:
          action.type_list == "list"
            ? action.product_count
            : previousState.productCount,
        productListData:
          action.type_list == "more"
            ? previousState.productListData.concat(action.product_list)
            : action.product_list,
      };
    case ERROR_GET_PRODUCT_LIST:
      return {
        ...previousState,
        productListErrorMessage: action.message,
        productListIsLoading: false,
      };
      case START_GET_GALLERY_LIST:
        return {
          ...previousState,
          galleryListIsLoading: true,
          galleryListErrorMessage: "",
        };
      case SUCCESS_GET_GALLERY_LIST:
        return {
          ...previousState,
          galleryListIsLoading: false,
          galleryCount: action.result_count,
          galleryListData:
            action.type_list == "more"
              ? previousState.galleryListData.concat(action.gallery_list)
              : action.gallery_list,
        };
      case ERROR_GET_GALLERY_LIST:
        return {
          ...previousState,
          galleryListErrorMessage: action.message,
          galleryListIsLoading: false,
        };
    case START_GET_CATEGORY_LIST:
      return {
        ...previousState,
        categoryListIsLoading: true,
        categoryListErrorMessage: "",
      };
    case SUCCESS_GET_CATEGORY_LIST:
      return {
        ...previousState,
        categoryListIsLoading: false,
        productCategoryListData: action.category_list,
      };
    case ERROR_GET_CATEGORY_LIST:
      return {
        ...previousState,
        categoryListErrorMessage: action.message,
        categoryListIsLoading: false,
      };
    case START_GET_BRAND_LIST:
      return {
        ...previousState,
        brandIsLoading: true,
        productListErrorMessage: "",
      };
    case SUCCESS_GET_BRAND_LIST:
      return {
        ...previousState,
        brandListIsLoading: false,
        brandListData: action.brand_list,
      };
    case ERROR_GET_BRAND_LIST:
      return {
        ...previousState,
        productListErrorMessage: action.message,
        brandListIsLoading: false,
      };
    case START_GET_PRODUCT_DETAILS:
      return {
        ...previousState,
        productDetailsIsLoading: true,
        productDetailsErrorMessage: "",
        productDetailsData: "",
      };
    case SUCCESS_GET_PRODUCT_DETAILS:
      return {
        ...previousState,
        productDetailsIsLoading: false,
        productDetailsData: action.product_details,
      };
    case ERROR_GET_PRODUCT_DETAILS:
      return {
        ...previousState,
        productListErrorMessage: action.message,
        productDetailsIsLoading: false,
        productDetailsData: "",
      };
    case START_PRODUCT_ADD_TO_CART:
      return {
        ...previousState,
        productAddToCartIsLoading: true,
        productDetailsErrorMessage: "",
        productDetailsSuccessMessage: "",
        productCartAdded: false,
      };
    case SUCCESS_PRODUCT_ADD_TO_CART:
      let cart_product = previousState.productDetailsData;
      cart_product["cart"] = "";
      return {
        ...previousState,
        productAddToCartIsLoading: false,
        productCartCount: action.cartCount,
        productCartAdded: true,
        productDetailsSuccessMessage: action.message,
        productDetailsData: cart_product,
        cartListData: action.cart_list_data,
      };
    case ERROR_PRODUCT_ADD_TO_CART:
      return {
        ...previousState,
        productDetailsErrorMessage: action.message,
        productAddToCartIsLoading: false,
        productCartAdded: false,
      };
    case START_GET_CART_LIST:
      return {
        ...previousState,
        cartIsLoading: true,
        cartErrorMessage: "",
        cartListData: [],
      };
    case SUCCESS_GET_CART_LIST:
      return {
        ...previousState,
        cartIsLoading: false,
        cartListData: action.list_data,
      };
    case ERROR_GET_CART_LIST:
      return {
        ...previousState,
        cartErrorMessage: action.message,
        cartIsLoading: false,
        cartListData: [],
      };
    case START_CART_PRODUCT_COUNT_UPDATE:
      return {
        ...previousState,
        cartUpdateIsLoading: true,
        cartErrorMessage: "",
      };
    case SUCCESS_CART_PRODUCT_COUNT_UPDATE:
      const newCartListData = previousState.cartListData.map((t) =>
        t.id == decrypt(action.cartId)
          ? {
              ...t,
              order_quantity: action.productCount,
            }
          : t
      );

      return {
        ...previousState,
        cartUpdateIsLoading: false,
        cartListData: newCartListData,
      };
    case ERROR_CART_PRODUCT_COUNT_UPDATE:
      return {
        ...previousState,
        cartErrorMessage: action.message,
        cartUpdateIsLoading: false,
      };
    case START_PRODUCT_REMOVE_TO_CART:
      return {
        ...previousState,
        cartUpdateIsLoading: true,
        cartErrorMessage: "",
      };
    case SUCCESS_PRODUCT_REMOVE_TO_CART:
      
      const removeCartListData = previousState.cartListData.filter(
        (product) => {
          return product.id != decrypt(action.cartId);
        }
      );
      return {
        ...previousState,
        cartUpdateIsLoading: false,
        cartListData: removeCartListData,
        productCartCount: removeCartListData.length,
      };
    case ERROR_PRODUCT_REMOVE_TO_CART:
      return {
        ...previousState,
        cartErrorMessage: action.message,
        cartUpdateIsLoading: false,
      };
    case START_PRODUCT_ADD_TO_WISHLIST:
      return {
        ...previousState,
        wishlistIsLoading: true,
      };
    case SUCCESS_PRODUCT_ADD_TO_WISHLIST:
      let newWishlistData = previousState.wishlistData;
      if (action.action_type == "get") {
        newWishlistData = action.product_wishlist;
      }
      if (action.action_type == "delete") {
        newWishlistData = action.product_wishlist.filter((product) => {
          return product.id != decrypt(action.productId);
        });
      }
      return {
        ...previousState,
        wishlistIsLoading: false,
        wishlistData: newWishlistData,
      };
    case ERROR_PRODUCT_ADD_TO_WISHLIST:
      return {
        ...previousState,
        wishlistIsLoading: false,
        wishlistData: [],
      };
    case START_GET_BEST_SELLING_PRODUCT_LIST:
      return {
        ...previousState,
        bestSellingProductIsLoading: true,
      };
    case SUCCESS_GET_BEST_SELLING_PRODUCT_LIST:
      let bestSellingProducts = previousState.bestSellingProductList;
      let dealsOfDayProducts = previousState.dealsOfDayProductList;
      let newArrivalProducts = previousState.newArrivalProductsList;
      let smallGadgets = previousState.smallGadgetsProductList;
      let importedGadgets = previousState.importedGadgetsProductList;

      console.log(previousState,"these are small ")
     
      if (action.product_type == "best") {
        bestSellingProducts = action.product_list;
      }
      if (action.product_type == "day") {
        dealsOfDayProducts = action.product_list;
      }
      if (action.product_type == "new-arrival") {
        newArrivalProducts = action.product_list;
      }

      if (action.product_type == "small") {
        
        smallGadgets = action.product_list;
      }
      if (action.product_type == "imported") {
        importedGadgets = action.product_list;
      }

      return {
        ...previousState,
        bestSellingProductIsLoading: false,
        bestSellingProductList: bestSellingProducts,
        dealsOfDayProductList: dealsOfDayProducts,
        newArrivalProductsList: newArrivalProducts,
        smallGadgetsProductList:smallGadgets,
        importedGadgetsProductList:importedGadgets
      };
    case ERROR_GET_BEST_SELLING_PRODUCT_LIST:
      return {
        ...previousState,
        bestSellingProductIsLoading: false,
        bestSellingProductList: [],
      };
    case START_GET_PRODUCT_RATING_LIST:
      return {
        ...previousState,
        productRatingIsLoading: true,
      };
    case SUCCESS_GET_PRODUCT_RATING_LIST:
      return {
        ...previousState,
        productRatingIsLoading: false,
        productRatingList: action.product_rating_list,
      };
    case ERROR_GET_PRODUCT_RATING_LIST:
      return {
        ...previousState,
        productRatingIsLoading: false,
        productRatingList: [],
      };
    case START_USER_ORDER:
      return {
        ...previousState,
        userOrderIsLoading: true,
        userOrderSent: false,
        OrderSuccessMessage: "",
        OrderErrorMessage: "",
      };
    case SUCCESS_USER_ORDER:
      let order_data = previousState.userOrderList;
      if (action.action_type == "get") {
        order_data = action.product_rating_list;
      }
      if (action.action_type == "cancel") {
        order_data = previousState.userOrderList.map((t) =>
          t.id == decrypt(action.order_id)
            ? {
                ...t,
                cancelled_at: Date.now(),
                status: 2,
              }
            : t
        );
      }
      if (action.action_type == "return") {
        order_data = previousState.userOrderList.map((t) =>
          t.id == decrypt(action.order_id)
            ? {
                ...t,
                status: 4,
              }
            : t
        );
      }
      return {
        ...previousState,
        userOrderIsLoading: false,
        userOrderSent: true,
        userOrderList: order_data,
        productCartCount:
          action.action_type == "post" ? 0 : previousState.productCartCount,
        OrderSuccessMessage:
          action.action_type == "post" ||
          action.action_type == "cancel" ||
          action.action_type == "return"
            ? action.message
            : "",
      };
    case ERROR_USER_ORDER:
      return {
        ...previousState,
        userOrderIsLoading: false,
        userOrderList: [],
      };
    case ERROR_GET_PRODUCT_RATING_LIST:
      return {
        ...previousState,
        productRatingIsLoading: false,
        productRatingList: [],
      };
    case START_USER_PRODUCT_REVIEW:
      return {
        ...previousState,
        productReviewIsLoading: true,
        productReviewSent: false,
        productReviewSuccessMessage: "",
        productReviewErrorMessage: "",
      };
    case SUCCESS_USER_PRODUCT_REVIEW:
      let review_order_data = previousState.userOrderList.map((t) =>
        t.order_products.length > 0
          ? {
              ...t,
              order_products:
                action.product_id !== ""
                  ? t.order_products.map((c) =>
                      c.id == decrypt(action.product_id)
                        ? {
                            ...c,
                            product: {
                              product_image: c.product_image,
                              product_review: "",
                            },
                          }
                        : c
                    )
                  : t.order_products,
            }
          : t
      );

      return {
        ...previousState,
        productReviewIsLoading: false,
        productReviewSent: true,
        userOrderList: review_order_data,
        productReviewSuccessMessage: action.message,
      };
    case ERROR_USER_PRODUCT_REVIEW:
      return {
        ...previousState,
        productReviewIsLoading: false,
        productReviewSent: false,
        productReviewErrorMessage: action.message,
      };
    case START_GET_SEARCH_PRODUCT_LIST:
      return {
        ...previousState,
        searchProductIsLoading: true,
        searchProductList: [],
      };
    case SUCCESS_GET_SEARCH_PRODUCT_LIST:
      return {
        ...previousState,
        searchProductIsLoading: false,
        searchProductList: action.product_list,
      };
    case ERROR_GET_SEARCH_PRODUCT_LIST:
      return {
        ...previousState,
        searchProductIsLoading: false,
        searchProductList: [],
      };
    case START_BULK_INQUIRY:
      return {
        ...previousState,
        bulkInquiryIsLoading: true,
      };
    case SUCCESS_BULK_INQUIRY:
      return {
        ...previousState,
        bulkInquiryIsLoading: false,
        bulkInquirySuccessMessage: action.message,
      };
    case ERROR_BULK_INQUIRY:
      return {
        ...previousState,
        bulkInquiryIsLoading: false,
        bulkInquiryErrorMessage: action.message,
      };
    case SET_PRODUCT_TYPE_OF_LIST:
      return {
        ...previousState,
        productTypeOfList: action.productType ?? "",
      };
    case START_GET_STATE_CHARGES_LIST:
      return {
        ...previousState,
        stateCharges: [],
      };
    case SUCCESS_GET_STATE_CHARGES_LIST:
      return {
        ...previousState,
        stateCharges: action?.state_charges ?? [],
      };
    case ERROR_GET_STATE_CHARGES_LIST:
      return {
        ...previousState,
        stateCharges: [],
      };
    default:
      return previousState || initState;
  }
};

export default myProfile;
