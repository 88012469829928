import React, { useState, useEffect, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import {
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";
import clsx from "clsx";
import {
  EMPTY_USER_INIT_STATE,
  START_USER_SIGN_IN,
  START_USER_SIGN_UP,
  START_USER_FORGOT_PASSWORD,
  START_USER_VERIFY_OTP,
} from "../../actions/user";
import { NotificationManager } from "react-notifications";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";

const useStyles = makeStyles((theme) => ({
  rootLoginDailog: {
    flexGrow: 0,
    marginTop: 10,
    "&>hr": {
      margin: "20px 0px",
    },
  },
  mainDialogCls: {
    width: "400px !important",
    maxHeight: "500px !important",
    maxWidth: "unset",
    [theme.breakpoints.down("xs")]: {
      width: "90% !important",
    },
  },
  dialogContentCls: {},
  loginDialogCls: {
    "&>h2": {
      textAlign: "center",
      marginTop: 0,
      marginBottom: 5,
      cursor: "pointer",
      "&>button": {
        "&>span": {
          fontSize: 16,
          fontWeight: 600,
        },
      },
    },
    "&>p": {
      margin: 0,
      textAlign: "center",
      fontSize: 14,
      marginBottom: 10,
      "&>span": {
        color: "#3AA3F9",
        cursor: "pointer",
      },
    },
    "&>form": {
      "&>p": {
        margin: 0,
        textAlign: "right",
        fontSize: 14,
        marginBottom: 10,
        "&>span": {
          color: "#3AA3F9",
          cursor: "pointer",
        },
      },
    },
  },
  befaultButtonCls: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 20,
    "&>button": {
      "&:nth-of-type(1)": {
        marginRigth: "auto",
        display: "block",
      },
      "&:nth-of-type(2)": {
        marginLeft: "auto",
        display: "block",
        "&>span": {
          display: "flex",
          alignItems: "center",
          "&>div": {
            width: "15px !important",
            height: "15px !important",
            marginRight: 10,
            color: "#FFF",
          },
        },
      },
    },
  },
  loginOTPCls: {
    display: "flex",
    justifyContent: "space-between",
  },
  otpInputCls: {
    justifyContent: "center",
    margin: "80px 0px 15px 0px",
    "&>div": {
      "&>input": {
        width: "2em !important",
        height: "2em !important",
      },
    },
  },
}));

let messageStatus = 0;
function LoginDailog(props) {
  const classes = useStyles();
  const history = useNavigate();
  const {
    loginDailogStatus,
    openLoginDailog,
    userEmptyInitStateAction,
    userSignInAction,
    userSignUpAction,
    userSignUpAndSignInLoading,
    userSignUpAndSignStatus,
    userSignUpAndSignInErrorMessage,
    userDetailsLoading,
    userDetailsData,
    userForgotPasswordAction,
    forgotPasswordIsLoading,
    passwordStatus,
    forgotPasswordSuccessMessage,
    forgotPasswordErrorMessage,
    userVerifyOTP,
    actionTypeStatus,
    userVerifyOTPErrorMessage,
    userSettingsDetails
  } = props;

  const [showStatus, setShowStatus] = useState(0);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userRole, setUserRole] = useState("customer");

  const  [message,setMessage] = useState(true)
  const newMessage = useCallback(
    () => message,
    [message]
  );
  const [userGender, setUserGender] = useState("male");
  // const [userDOB, setUserDOB] = useState("");
  const [otpValue, setotpValue] = useState("");

  useEffect(() => {
    if (userSignUpAndSignInErrorMessage != "" && messageStatus === 0) {
      messageStatus = 1;
      userEmptyInitStateAction("userSignUpAndSignInErrorMessage", "");
      NotificationManager.error(userSignUpAndSignInErrorMessage, "", 5000);
    }

    return () => {
      
    }
  }, [userSignUpAndSignInErrorMessage]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (userVerifyOTPErrorMessage != "") {
        messageStatus = 1;
        userEmptyInitStateAction("userVerifyOTPErrorMessage", "");
        NotificationManager.error(userVerifyOTPErrorMessage, "", 5000);
      }
    }, 500);

    return () => {
      clearTimeout(timeout)
    }
  }, [userVerifyOTPErrorMessage]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (forgotPasswordSuccessMessage) {
        NotificationManager.success(forgotPasswordSuccessMessage, "", 5000);
        userEmptyInitStateAction("forgotPasswordSuccessMessage", "");
        userEmptyInitStateAction("passwordStatus", false);
        setUserName("");
        setUserMobile("");
        setUserPassword("");
        setShowStatus(0);
        setotpValue("");
        if (actionTypeStatus == "forgot") {
          setUserEmail("");
          openLoginDailog(false);
        }
      }
    }, 500);

    return () =>{
      clearTimeout(timeout)
    }
  }, [forgotPasswordSuccessMessage]);

 
    useEffect(() => {
      if (forgotPasswordErrorMessage !== "") {
        let opennoti = setTimeout(() => {
          NotificationManager.error(forgotPasswordErrorMessage, "", 5000);
          userEmptyInitStateAction("forgotPasswordErrorMessage", "");
        }, 100);

        return () => {
          clearTimeout(opennoti)
        }
      }
    },[forgotPasswordErrorMessage])

  useEffect(() => {
    if (actionTypeStatus == "otp") {
      userEmptyInitStateAction("actionTypeStatus", "");
      setShowStatus(3);
    }
  }, [actionTypeStatus]);

  useEffect(() => {
  },[])

  useEffect(() => {

    if (userSignUpAndSignStatus) {

      setUserName("");
      setUserEmail("");
      setUserMobile("");
      setUserPassword("");
      setotpValue("");
      setShowStatus(0);
      openLoginDailog(false);
      userEmptyInitStateAction("userSignUpAndSignStatus", false);
      if (
        window.localStorage.getItem("redirectto") !== null &&
        window.localStorage.getItem("redirectto") !== ""
      ) {
        history(window.localStorage.getItem("redirectto"));
        window.localStorage.setItem("redirectto", "");
      } else {
        window.location.reload();
      }
    }
  }, [userSignUpAndSignStatus]);

  const openLoginDailogAction = (status) => {
    openLoginDailog(status);
    setShowStatus(0);
  };


  const showStatusAction = (status) => {
    var actionState = 0;
    if (status != 3) {
      setUserName("");
      setUserEmail("");
      setUserMobile("");
      setUserPassword("");
    }
    setotpValue("");
    if (userEmail.trim() === "" && status == 3) {
      NotificationManager.error(
        "Please enter valid Email ID/Mobile number",
        "",
        5000
      );
    }
    if (userEmail.trim() != "" && status == 3) {
      if (userEmail.trim().length !== 0) {
        let reg = /^([0|\[0-9]{1,5})?([0-9]{10})$/;
        if (reg.test(userEmail) !== false) {
          actionState = 1;
        }
      }
      let emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(userEmail)) {
        actionState = 1;
      }
      if (actionState === 0) {
        NotificationManager.error(
          "Please enter valid Email ID/Mobile number",
          "",
          5000
        );
      }
    }
    if (actionState == 1 || status != 3) {
      
      if (status == 3) {
        userForgotPasswordAction(userEmail, "otp");
      }
      if (status !== 3) {
        setShowStatus(status);
      }
    }
  };

  const signInAction = (event) => {
    event.preventDefault();
    if (userEmail.trim().length !== 0) {
    } else {
      NotificationManager.error(
        "To proceed, please enter your email address",
        "",
        5000
      );
      return;
    }
    if (userPassword.trim().length === 0) {
      NotificationManager.error(
        "To proceed, please enter your password",
        "",
        5000
      );
      return;
    } else {
      if (userPassword.trim().length < 8) {
        NotificationManager.error(
          "password must be at least 8 characters long",
          "",
          5000
        );
        return;
      }
    }
    messageStatus = 0;
    userSignInAction({ email: userEmail, password: userPassword });
  };

  const signUpAction = (event) => {
    event.preventDefault();
    if (userEmail.trim().length !== 0) {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailRegex.test(userEmail)) {
        NotificationManager.error(
          "Please double-check that the email address you entered is correct.",
          "",
          5000
        );
        return;
      }
    } else {
      NotificationManager.error(
        "To proceed, please enter your email address",
        "",
        5000
      );
      return;
    }
    if (userPassword.trim().length === 0) {
      NotificationManager.error(
        "To proceed, please enter your password",
        "",
        5000
      );
      return;
    } else {
      if (userPassword.trim().length < 8) {
        NotificationManager.error(
          "password must be at least 8 characters long",
          "",
          5000
        );
        return;
      }
    }
    if (userName.trim().length === 0) {
      NotificationManager.error("To proceed, please enter your name", "", 5000);
      return;
    }
    if (userMobile.trim().length !== 0) {
      let reg = /^([0|\[0-9]{1,5})?([0-9]{10})$/;
      if (reg.test(userMobile) == false) {
        NotificationManager.error(
          "Please provide a valid mobile number",
          "",
          5000
        );
        return;
      }
    } else {
      NotificationManager.error("Please enter mobile number", "", 5000);
    }
    if (userRole.trim().length === 0) {
      NotificationManager.error("To proceed, please select your role", "", 5000);
      return;
    }
    if (userGender.trim().length === 0) {
      NotificationManager.error("To proceed, please select your gender", "", 5000);
      return;
    }
    // if (userDOB.trim().length === 0) {
    //   NotificationManager.error("To proceed, please select your birth date", "", 5000);
    //   return;
    // }
    messageStatus = 0;
    userSignUpAction({
      name: userName,
      email: userEmail,
      m_number: userMobile,
      password: userPassword,
      role: userRole,
      gender: userGender,
      // dob: userDOB,
    });
  };

  const forgotPasswordAction = (event) => {
    event.preventDefault();
    if (userEmail.trim().length !== 0) {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      
        if (!emailRegex.test(userEmail)) {
        NotificationManager.error(
          "Please double-check that the email address or mobile number you entered is correct.",
          "",
          5000
        );
        return;
      }
    } else {
      NotificationManager.error(
        "To proceed, please enter your email address",
        "",
        5000
      );
      return;
    }
    messageStatus = 0;
    userForgotPasswordAction(userEmail, "forgot");
  };

  const verifyOTPAction = (event) => {
    event.preventDefault();
    if (otpValue.trim().length !== 0) {
      userVerifyOTP(userEmail, otpValue);
    } else {
      NotificationManager.error("Please enter OTP", "", 5000);
      return;
    }
  };

  return (
    <div className={clsx(classes.rootFooter)}>
      <Dialog
        onClose={() => openLoginDailogAction(false)}
        aria-labelledby="customized-dialog-title"
        open={loginDailogStatus}
        classes={{
          paper: classes.mainDialogCls,
        }}
      >
        <DialogContent dividers>
          {showStatus === 0 && (
            <div className={classes.loginDialogCls}>
              <h2>
                <Button variant="contained" onClick={() => showStatusAction(0)}>
                  Sign in
                </Button>{" "}
                <Button variant="outlined" onClick={() => showStatusAction(1)}>
                  Sign Up
                </Button>
              </h2>
              <form onSubmit={(event) => signInAction(event)}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="username"
                  id="username"
                  label="Email"
                  placeholder="Enter your email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={userEmail}
                  onChange={(event) => setUserEmail(event.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={userPassword}
                  onChange={(event) => setUserPassword(event.target.value)}
                />
                <p className={classes.loginOTPCls}>
                  <span onClick={() => showStatusAction(3)}>
                    Login with OTP
                  </span>
                  <span onClick={() => showStatusAction(2)}>
                    forgot password
                  </span>
                </p>
                <div className={classes.befaultButtonCls}>
                  <Button
                    variant="outlined"
                    onClick={() => openLoginDailogAction(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    {userDetailsLoading && <CircularProgress />}
                    Sign in
                  </Button>
                </div>
              </form>
            </div>
          )}
          {showStatus === 1 && (
            <div className={classes.loginDialogCls}>
              <h2>
                <Button variant="outlined" onClick={() => showStatusAction(0)}>
                  Sign in
                </Button>{" "}
                <Button variant="contained" onClick={() => showStatusAction(1)}>
                  Sign Up
                </Button>
              </h2>

              <form onSubmit={(event) => signUpAction(event)}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="name"
                  id="name"
                  label="Name"
                  placeholder="Enter your name"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={userName}
                  onChange={(event) => setUserName(event.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  id="email"
                  label="Email"
                  placeholder="Enter your email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={userEmail}
                  onChange={(event) => setUserEmail(event.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="mobile"
                  id="mobile"
                  label="Mobile number"
                  placeholder="Enter your mobile number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={userMobile}
                  onChange={(event) => setUserMobile(event.target.value)}
                />
                <InputLabel id="role-label" style={{marginTop:'10px',fontSize:'13px'}}>Select role *</InputLabel>
                <Select
                  margin="normal"
                  required
                  fullWidth
                  name="role"
                  labelId="role-label"
                  label="Role"
                  id="role"
                  value={userRole}
                  onChange={(event) => setUserRole(event.target.value)}
                >
                  {/* <option value={"admin"}>user</option> */}
                  {userSettingsDetails?.roles?.map((role) => <MenuItem value={role?.key}>{role?.value}</MenuItem> )}
                </Select>

                <InputLabel id="gender-label" style={{marginTop:'10px',fontSize:'13px'}}>Select gender *</InputLabel>
                 <Select
                  margin="normal"
                  required
                  fullWidth
                  name="gender"
                  labelId="gender-label"
                  label="Gender"
                  id="gender"
                  value={userGender}
                  onChange={(event) => setUserGender(event.target.value)}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>

                {/* <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="dob"
                  id="dob"
                  label="Birth date"
                  type="date"
                  placeholder="Enter your birth date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={userDOB}
                  onChange={(event) => setUserDOB(event.target.value)}
                /> */}

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  placeholder="Enter your password"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={userPassword}
                  onChange={(event) => setUserPassword(event.target.value)}
                />
                <div className={classes.befaultButtonCls}>
                  <Button
                    variant="outlined"
                    onClick={() => openLoginDailogAction(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    {userDetailsLoading && <CircularProgress />}
                    Sign up
                  </Button>
                </div>
              </form>
            </div>
          )}
          {showStatus === 2 && (
            <div className={classes.loginDialogCls}>
              <h2>Forgot Password</h2>
              <p>
                You have a password?{" "}
                <span onClick={() => showStatusAction(0)}>Sign In</span>
              </p>
              <form onSubmit={(event) => forgotPasswordAction(event)}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="username"
                  id="username"
                  label="Email"
                  placeholder="Enter your email"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={userEmail}
                  onChange={(event) => setUserEmail(event.target.value)}
                />

                <div className={classes.befaultButtonCls}>
                  <Button
                    variant="outlined"
                    onClick={() => openLoginDailogAction(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    {userDetailsLoading && <CircularProgress />}
                    Reset Password
                  </Button>
                </div>
              </form>
            </div>
          )}
          {showStatus === 3 && (
            <div className={classes.loginDialogCls}>
              <h2>
                Please enter the OTP sent to <span></span>
              </h2>
              <p>
                {userEmail}{" "}
                <span onClick={() => showStatusAction(0)}>Change</span>
              </p>
              <form onSubmit={(event) => verifyOTPAction(event)}>
                <OtpInput
                  value={otpValue}
                  onChange={(otp) => setotpValue(otp)}
                  numInputs={6}
                  separator={<span> &nbsp; - &nbsp; </span>}
                  containerStyle={classes.otpInputCls}
                  renderInput={(props) => <input {...props} />}
                />
                <p>
                  Not received your code?{" "}
                  <span onClick={() => showStatusAction(3)}>Resend code</span>
                </p>
                <div className={classes.befaultButtonCls}>
                  <Button
                    variant="outlined"
                    onClick={() => openLoginDailogAction(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    {userDetailsLoading && <CircularProgress />}
                    Verify
                  </Button>
                </div>
              </form>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userDetailsData: state.user.userDetailsData,
  userDetailsLoading: state.user.userDetailsLoading,
  userSignUpAndSignStatus: state.user.userSignUpAndSignStatus,
  userSignUpAndSignInLoading: state.user.userSignUpAndSignInLoading,
  userSignUpAndSignInErrorMessage: state.user.userSignUpAndSignInErrorMessage,
  forgotPasswordIsLoading: state.user.forgotPasswordIsLoading,
  passwordStatus: state.user.passwordStatus,
  forgotPasswordSuccessMessage: state.user.forgotPasswordSuccessMessage,
  forgotPasswordErrorMessage: state.user.forgotPasswordErrorMessage,
  actionTypeStatus: state.user.actionTypeStatus,
  userVerifyOTPErrorMessage: state.user.userVerifyOTPErrorMessage,
  userSettingsDetails: state.user.userSettingsDetails
});

const mapDispatchToProps = (dispatch) => ({
  userEmptyInitStateAction: (key, value) =>
    dispatch({ type: EMPTY_USER_INIT_STATE, key, value }),
  userSignInAction: (data) => dispatch({ type: START_USER_SIGN_IN, data }),
  userSignUpAction: (data) => dispatch({ type: START_USER_SIGN_UP, data }),
  userForgotPasswordAction: (email, actionType) =>
    dispatch({ type: START_USER_FORGOT_PASSWORD, email, actionType }),
  userVerifyOTP: (email, otpValue) =>
    dispatch({ type: START_USER_VERIFY_OTP, email, otpValue }),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginDailog);
