import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL
console.log(baseUrl)

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 500000, // 5 min
  headers: { "Content-Type": "application/json", Accept: "application/json" },
  validateStatus: function (status) {
    return (status >= 200 && status < 300) || status === 401 || status === 422; // default
  },
});                                                                                           

function setAccessTokenInLocalStorage(token) {
  const { localStorage } = window;
  try {
    return localStorage.setItem("token", token);
  } catch (e) {
    // memory full
    return undefined;
  }
}

function getAccessTokenFromLocalStorage() {
  const { localStorage } = window;
  const token = localStorage.getItem("token");
  return token || "";
}

function post(url, paramObj, headers = {}) {
  //instance.defaults.headers.common['Authorization'] = "Bearer " + getAccessTokenFromLocalStorage();
  instance.defaults.headers.common[
    "x-auth-token"
  ] = getAccessTokenFromLocalStorage();

  return instance
    .post(url, paramObj)
    .then((response) => {
      switch (response.status) {
        case 200:
          return response.data;
        case 401:
          const { localStorage } = window;
          localStorage.clear();
          window.location.href = "/home";
          return {
            status: true,
          };
        case 422:
          return { status: false, message: response.data.message };
        default:
          return {
            status: false,
            message: "Something went wrong!",
            redirect_to_login: true,
          };
      }
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch((error) => {
      // window.location.href = "/maintenance";
      return {
        status: false,
        message: error.message || "Something went to wrong! Try again later",
      };
    });
}

function put(url, paramObj, headers = {}) {
  instance.defaults.headers.common[
    "x-auth-token"
  ] = getAccessTokenFromLocalStorage();
  return instance
    .put(url, paramObj)
    .then((response) => {
      switch (response.status) {
        case 200:
          return response.data;
        case 401:
          const { localStorage } = window;
          localStorage.clear();
          window.location.href = "/home";
          return {
            status: true,
          };
        case 422:
          return { status: false, message: response.data.message };
        default:
          return { status: false, message: "Something went wrong!" };
      }
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch((error) => {
      // window.location.href = "/maintenance";
      return {
        status: false,
        message: error.message || "Something went to wrong! Try again later",
      };
    });
}

function get(url, paramObj = {}, headers = {}) {
  //instance.defaults.headers.common['Authorization'] = "Bearer " + getAccessTokenFromLocalStorage();
  instance.defaults.headers.common[
    "x-auth-token"
  ] = getAccessTokenFromLocalStorage();
  return instance
    .get(url, { params: paramObj })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 401) {
        const { localStorage } = window;
        localStorage.clear();
        window.location.href = "/home";
        return {
          status: true,
        };
      }
      return {
        success: false,
        message: response.message || "Ill formed response!",
        redirect_to_login: true,
      };
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch((error) => {
      // window.location.href = "/maintenance";
      return {
        status: false,
        message: error.message || "Something went wrong! Try again later",
      };
    });
}

function deleteM(url, paramObj = {}, headers = {}) {
  //instance.defaults.headers.common['Authorization'] = "Bearer " + getAccessTokenFromLocalStorage();
  instance.defaults.headers.common[
    "x-auth-token"
  ] = getAccessTokenFromLocalStorage();
  return instance
    .delete(url, { params: paramObj })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 401) {
        const { localStorage } = window;
        localStorage.clear();
        window.location.href = "/home";
        return {
          status: true,
        };
      }
      return {
        success: false,
        message: response.message || "Ill formed response!",
      };
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch((error) => {
      return {
        status: false,
        message: error.message || "Something went wrong! Try again later",
      };
    });
}

function getServer(url, paramObj = {}, headers = {}) {
  //instance.defaults.headers.common['Authorization'] = "Bearer " + getAccessTokenFromLocalStorage();
  instance.defaults.headers.common[
    "x-auth-token"
  ] = getAccessTokenFromLocalStorage();
  return instance
    .get(url, { params: paramObj })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      } else if (response.status === 401) {
        const { localStorage } = window;
        localStorage.clear();
        window.location.href = "/home";
        return {
          status: true,
        };
      }
      return {
        success: false,
        message: response.message || "Ill formed response!",
        redirect_to_login: true,
      };
    })
    .then((response) => {
      if (response.success) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch((error) => {
      //// window.location.href = "/maintenance";
      return {
        status: false,
        message: error.message || "Something went wrong! Try again later",
      };
    });
}

export default {
  get,
  getServer,
  post,
  put,
  deleteM,
  getAccessTokenFromLocalStorage,
  setAccessTokenInLocalStorage,
};
