export const EMPTY_PRODUCT_INIT_STATE = "EMPTY_PRODUCT_INIT_STATE";
export const START_GET_PRODUCT_LIST = "START_GET_PRODUCT_LIST";
export const SUCCESS_GET_PRODUCT_LIST = "SUCCESS_GET_PRODUCT_LIST";
export const ERROR_GET_PRODUCT_LIST = "ERROR_GET_PRODUCT_LIST";

export const START_GET_GALLERY_LIST = "START_GET_GALLERY_LIST";
export const SUCCESS_GET_GALLERY_LIST = "SUCCESS_GET_GALLERY_LIST";
export const ERROR_GET_GALLERY_LIST = "ERROR_GET_GALLERY_LIST";

export const START_GET_CATEGORY_LIST = "START_GET_CATEGORY_LIST";
export const SUCCESS_GET_CATEGORY_LIST = "SUCCESS_GET_CATEGORY_LIST";
export const ERROR_GET_CATEGORY_LIST = "ERROR_GET_CATEGORY_LIST";

export const START_GET_BRAND_LIST = "START_GET_BRAND_LIST";
export const SUCCESS_GET_BRAND_LIST = "SUCCESS_GET_BRAND_LIST";
export const ERROR_GET_BRAND_LIST = "ERROR_GET_BRAND_LIST";

export const START_GET_PRODUCT_DETAILS = "START_GET_PRODUCT_DETAILS";
export const SUCCESS_GET_PRODUCT_DETAILS = "SUCCESS_GET_PRODUCT_DETAILS";
export const ERROR_GET_PRODUCT_DETAILS = "ERROR_GET_PRODUCT_DETAILS";

export const START_PRODUCT_ADD_TO_CART = "START_PRODUCT_ADD_TO_CART";
export const SUCCESS_PRODUCT_ADD_TO_CART = "SUCCESS_PRODUCT_ADD_TO_CART";
export const ERROR_PRODUCT_ADD_TO_CART = "ERROR_PRODUCT_ADD_TO_CART";

export const START_GET_CART_LIST = "START_GET_CART_LIST";
export const SUCCESS_GET_CART_LIST = "SUCCESS_GET_CART_LIST";
export const ERROR_GET_CART_LIST = "ERROR_GET_CART_LIST";

export const START_CART_PRODUCT_COUNT_UPDATE =
  "START_CART_PRODUCT_COUNT_UPDATE";
export const SUCCESS_CART_PRODUCT_COUNT_UPDATE =
  "SUCCESS_CART_PRODUCT_COUNT_UPDATE";
export const ERROR_CART_PRODUCT_COUNT_UPDATE =
  "ERROR_CART_PRODUCT_COUNT_UPDATE";

export const START_PRODUCT_REMOVE_TO_CART = "START_PRODUCT_REMOVE_TO_CART";
export const SUCCESS_PRODUCT_REMOVE_TO_CART = "SUCCESS_PRODUCT_REMOVE_TO_CART";
export const ERROR_PRODUCT_REMOVE_TO_CART = "ERROR_PRODUCT_REMOVE_TO_CART";

export const START_PRODUCT_ADD_TO_WISHLIST = "START_PRODUCT_ADD_TO_WISHLIST";
export const SUCCESS_PRODUCT_ADD_TO_WISHLIST =
  "SUCCESS_PRODUCT_ADD_TO_WISHLIST";
export const ERROR_PRODUCT_ADD_TO_WISHLIST = "ERROR_PRODUCT_ADD_TO_WISHLIST";

export const START_GET_BEST_SELLING_PRODUCT_LIST =
  "START_GET_BEST_SELLING_PRODUCT_LIST";
export const SUCCESS_GET_BEST_SELLING_PRODUCT_LIST =
  "SUCCESS_GET_BEST_SELLING_PRODUCT_LIST";
export const ERROR_GET_BEST_SELLING_PRODUCT_LIST =
  "ERROR_GET_BEST_SELLING_PRODUCT_LIST";


export const START_GET_SMALL_GADGETS_PRODUCT_LIST =
  "START_GET_BEST_SELLING_PRODUCT_LIST";
export const SUCCESS_GET_SMALL_GADGETS_PRODUCT_LIST =
  "SUCCESS_GET_BEST_SELLING_PRODUCT_LIST";
export const ERROR_GET_SMALL_GADGETS_PRODUCT_LIST =
  "ERROR_GET_BEST_SELLING_PRODUCT_LIST";


export const START_GET_IMPORTED_GADGETS_PRODUCT_LIST =
  "START_GET_BEST_SELLING_PRODUCT_LIST";
export const SUCCESS_GET_IMPORTED_GADGETS__PRODUCT_LIST =
  "SUCCESS_GET_BEST_SELLING_PRODUCT_LIST";
export const ERROR_GET_IMPORTED_GADGETS__PRODUCT_LIST =
  "ERROR_GET_BEST_SELLING_PRODUCT_LIST";






export const START_GET_PRODUCT_RATING_LIST = "START_GET_PRODUCT_RATING_LIST";
export const SUCCESS_GET_PRODUCT_RATING_LIST =
  "SUCCESS_GET_PRODUCT_RATING_LIST";
export const ERROR_GET_PRODUCT_RATING_LIST = "ERROR_GET_PRODUCT_RATING_LIST";

export const START_USER_ORDER = "START_USER_ORDER";
export const SUCCESS_USER_ORDER = "SUCCESS_USER_ORDER";
export const ERROR_USER_ORDER = "ERROR_USER_ORDER";

export const START_USER_PRODUCT_REVIEW = "START_USER_PRODUCT_REVIEW";
export const SUCCESS_USER_PRODUCT_REVIEW = "SUCCESS_USER_PRODUCT_REVIEW";
export const ERROR_USER_PRODUCT_REVIEW = "ERROR_USER_PRODUCT_REVIEW";

export const START_GET_SEARCH_PRODUCT_LIST = "START_GET_SEARCH_PRODUCT_LIST";
export const SUCCESS_GET_SEARCH_PRODUCT_LIST =
  "SUCCESS_GET_SEARCH_PRODUCT_LIST";
export const ERROR_GET_SEARCH_PRODUCT_LIST = "ERROR_GET_SEARCH_PRODUCT_LIST";

export const START_BULK_INQUIRY = "START_BULK_INQUIRY";
export const SUCCESS_BULK_INQUIRY = "SUCCESS_BULK_INQUIRY";
export const ERROR_BULK_INQUIRY = "ERROR_BULK_INQUIRY";

export const SET_PRODUCT_TYPE_OF_LIST = "SET_PRODUCT_TYPE_OF_LIST";

export const START_GET_STATE_CHARGES_LIST = "START_GET_STATE_CHARGES_LIST";
export const SUCCESS_GET_STATE_CHARGES_LIST = "SUCCESS_GET_STATE_CHARGES_LIST";
export const ERROR_GET_STATE_CHARGES_LIST = "ERROR_GET_STATE_CHARGES_LIST";
