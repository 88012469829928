import React, { Suspense, lazy, useEffect, useState } from "react";
import {  Route, Redirect, useLocation,Routes } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "./App.css";
import Header from "./components/Comman/Header";
import Footer from "./components/Comman/Footer";
import PageLoading from "./components/PageLoading";
import RedirectionMiddleWare from "./components/Comman/RedirectionMiddleware";

const Home = lazy(() => import("./containers/Home"));
const ProductListContainer = lazy(() =>
  import("./containers/ProductListContainer")
);
const ProductDetailsContainer = lazy(() =>
  import("./containers/ProductDetailsContainer")
);
const CartContainer = lazy(() => import("./containers/CartContainer"));
const PlaceOrderContainer = lazy(() =>
  import("./containers/PlaceOrderContainer")
);
const UserAccountContainer = lazy(() =>
  import("./containers/UserAccountContainer")
);

const ResetPasswordContainer = lazy(() =>
  import("./containers/ResetPasswordContainer")
);
const BestSellingProductContainer = lazy(() =>
  import("./containers/BestSellingProductContainer")
);
const DealsOfTheDayContainer = lazy(() =>
  import("./containers/DealsOfTheDayContainer")
);

const SearchProductListContainer = lazy(() =>
  import("./containers/SearchProductListContainer")
);

const GalleryListContainer = lazy(() =>
  import("./containers/GalleryListContainer")
);

// const PrivacyContainer = lazy(() => import("./containers/PrivacyContainer"));
// const AboutUsContainer = lazy(() => import("./containers/AboutUsContainer"));
// const CancelltionAndReturnsContainer = lazy(() =>
//   import("./containers/CancelltionAndReturnsContainer")
// );
// const ContactUsContainer = lazy(() =>
//   import("./containers/ContactUsContainer")
// );
// const FaqContainer = lazy(() => import("./containers/FaqContainer"));
// const PaymentsContainer = lazy(() => import("./containers/PaymentsContainer"));
// const ReturnPolicyContainer = lazy(() =>
//   import("./containers/ReturnPolicyContainer")
// );
// const SecurityContainer = lazy(() => import("./containers/SecurityContainer"));
// const ShippingContainer = lazy(() => import("./containers/ShippingContainer"));
// const TermsOfUseContainer = lazy(() =>
//   import("./containers/TermsOfUseContainer")
// );

const CommanPageContainer = lazy(() =>
  import("./containers/CommanPageContainer")
);

function Main() {
  return (
    <div className="App">
      <NotificationContainer />
      <Header />
      <div style={{marginTop:"70px"}} className="forwidth"></div>
      
      <Suspense fallback={<PageLoading />}>
        <Routes>
          <Route exact path="/" element={<Home/>} />

          <Route path="/rd/:url1" element={<RedirectionMiddleWare/>}></Route>
          <Route path="/rd/:url1/:url2" element={<RedirectionMiddleWare/>}></Route>
          <Route path="/rd/:url1/:url2/:url3" element={<RedirectionMiddleWare/>}></Route>
          
          <Route exact path="/home" element={<Home/>} />
          <Route exact path="/products" element={<ProductListContainer/>} />
          <Route
            exact
            path="/deals-of-the-day"
            element={<DealsOfTheDayContainer/>}
          />
          <Route
            exact
            path="/best-selling-product"
            element={<BestSellingProductContainer/>}
          />
          <Route
            exact
            path="/products/:category_slug"
            element={<ProductListContainer/>}
          />
          <Route
            exact
            path="/search-product/:productName"
            element={<SearchProductListContainer/>}
          />
          <Route
            exact
            path="/products/:category_slug/:sub_category_slug"
            element={<ProductListContainer/>}
          />
          <Route
            exact
            path=
              "/products/:category_slug/:sub_category_slug/:child_category_slug"
            
            element={<ProductListContainer/>}
          />
          <Route
            exact
            path="/product/:product_id/:name"
            element={<ProductDetailsContainer/>}
          />
          <Route exact path="/cart" element={<CartContainer/>} />
          <Route
            exact
            path="/place-order"
            element={<PlaceOrderContainer/>}
          />
          <Route
            exact
            path="/account/:menu"
            element={<UserAccountContainer/>}
          />

          <Route
            exact
            path="/password/reset/:password_token"
            element={<ResetPasswordContainer/>}
          />
          <Route exact path="/privacy" element={<CommanPageContainer/>} />
          <Route exact path="/about-us" element={<CommanPageContainer/>} />
          <Route
            exact
            path="/cancellation-and-returns"
            element={<CommanPageContainer/>}
          />
          <Route exact path="/gallery" element={<GalleryListContainer/>} />
          <Route exact path="/contact-us" element={<CommanPageContainer/>} />
          <Route exact path="/faq" element={<CommanPageContainer/>} />
          <Route exact path="/payments" element={<CommanPageContainer/>} />
          <Route
            exact
            path="/return-policy"
            element={<CommanPageContainer/>}
          />
          <Route exact path="/security" element={<CommanPageContainer/>} />
          <Route exact path="/shipping" element={<CommanPageContainer/>} />
          <Route
            exact
            path="/terms-of-use"
            element={<CommanPageContainer/>}
          />
        </Routes>
      </Suspense>
      <div >
        <Footer />
      </div>
    </div>
  );
}

export default Main;
