import { decrypt } from "../utils/customHooks";

import {
  EMPTY_USER_INIT_STATE,
  START_USER_SIGN_UP,
  SUCCESS_USER_SIGN_UP,
  ERROR_USER_SIGN_UP,
  START_USER_SIGN_IN,
  SUCCESS_USER_SIGN_IN,
  ERROR_USER_SIGN_IN,
  START_GET_USER_DETAILS,
  SUCCESS_GET_USER_DETAILS,
  ERROR_GET_USER_DETAILS,
  START_GET_BANNER_LIST,
  SUCCESS_GET_BANNER_LIST,
  ERROR_GET_BANNER_LIST,
  START_USER_ADDRESS_ACTION,
  SUCCESS_USER_ADDRESS_ACTION,
  ERROR_USER_ADDRESS_ACTION,
  START_UPDATE_USER_INFORMATION,
  SUCCESS_UPDATE_USER_INFORMATION,
  ERROR_UPDATE_USER_INFORMATION,
  START_UPDATE_USER_PASSWORD,
  SUCCESS_UPDATE_USER_PASSWORD,
  ERROR_UPDATE_USER_PASSWORD,
  START_USER_FORGOT_PASSWORD,
  SUCCESS_USER_FORGOT_PASSWORD,
  ERROR_USER_FORGOT_PASSWORD,
  START_USER_RESET_PASSWORD,
  SUCCESS_USER_RESET_PASSWORD,
  ERROR_USER_RESET_PASSWORD,
  START_GET_SUB_BANNER_LIST,
  SUCCESS_GET_SUB_BANNER_LIST,
  ERROR_GET_SUB_BANNER_LIST,
  START_GET_PAGE_DETAILS,
  SUCCESS_GET_PAGE_DETAILS,
  ERROR_GET_PAGE_DETAILS,
  START_GET_USER_SETTINGS,
  SUCCESS_GET_USER_SETTINGS,
  ERROR_GET_USER_SETTINGS,
  START_GET_ADMIN_VIDEO_LINK,
  SUCCESS_GET_ADMIN_VIDEO_LINK,
  ERROR_GET_ADMIN_VIDEO_LINK,
  START_USER_VERIFY_OTP,
  SUCCESS_USER_VERIFY_OTP,
  ERROR_USER_VERIFY_OTP,
  START_GET_ADS_BANNER_LIST,
  SUCCESS_GET_ADS_BANNER_LIST,
  ERROR_GET_ADS_BANNER_LIST
} from "../actions/user";

const initState = {
  userSignUpAndSignInLoading: false,
  userSignUpAndSignStatus: false,
  userSignUpAndSignInErrorMessage: "",
  userSignUpAndSignInSuccessMessage: "",
  userDetailsData: "",
  userDetailsLoading: false,
  userDetailsErrorMessage: "",
  bannerIsLoading: false,
  bannerErrorMessage: "",
  bannerData: [],
  addressIsLoading: false,
  addressCreated: false,
  addressErrorMessage: "",
  addressData: [],
  userDetailsIsLoading: false,
  userInfoSuccessMessage: "",
  userInfoErrorMessage: "",
  updatePasswordIsLoading: false,
  updatePasswordSuccessMessage: "",
  updatePasswordErrorMessage: "",
  forgotPasswordIsLoading: false,
  forgotPasswordSuccessMessage: "",
  passwordStatus: false,
  actionTypeStatus: "",
  forgotPasswordErrorMessage: "",
  resetPasswordIsLoading: false,
  resetPasswordSuccessMessage: "",
  resetPasswordErrorMessage: "",
  subBannerIsLoading: true,
  subBannerData: [],
  pageDetails: "",
  userSettingsDetails: "",
  videoLinkDataIsLoading: false,
  videoLinkData: [],
  userVerifyOTPIsLoading: false,
  userVerifyOTPErrorMessage: "",
  globalSearchText: "",
  deals_of_the_day_order: 1,
  best_selling_product_order: 2,
  new_arrival_order: 3,
  inquiry_whatspp_number: "",
  inquiry_whatspp_message: "Inquiry from web",
  inquiry_email_address: "",
  show_new_arrival: "1",
  show_best_selling_product: "1",
  show_deals_of_the_day: "1",
  adsBannerIsLoading: true,
  adsBannerData: [],
};

const myProfile = (previousState, action) => {
  switch (action.type) {
    case EMPTY_USER_INIT_STATE:
      return {
        ...previousState,
        [action.key]: action.value,
      };
    case START_USER_SIGN_UP:
      return {
        ...previousState,
        userSignUpAndSignInLoading: true,
        userSignUpAndSignInErrorMessage: "",
      };
    case SUCCESS_USER_SIGN_UP:
      return {
        ...previousState,
        userSignUpAndSignInLoading: false,
        userSignUpAndSignStatus: true,
        userDetailsData: action.userDetails,
      };
    case ERROR_USER_SIGN_UP:
      return {
        ...previousState,
        userSignUpAndSignInErrorMessage: action.message,
        userSignUpAndSignInLoading: false,
      };
    case START_USER_SIGN_IN:
      return {
        ...previousState,
        userSignUpAndSignInLoading: true,
        userSignUpAndSignInErrorMessage: "",
      };
    case SUCCESS_USER_SIGN_IN:
      return {
        ...previousState,
        userSignUpAndSignInLoading: false,
        userSignUpAndSignStatus: true,
        userDetailsData: action.userDetails,
      };
    case ERROR_USER_SIGN_IN:
      return {
        ...previousState,
        userSignUpAndSignInErrorMessage: action.message,
        userSignUpAndSignInLoading: false,
      };
    case START_GET_USER_DETAILS:
      return {
        ...previousState,
        userDetailsLoading: true,
        userDetailsErrorMessage: "",
      };
    case SUCCESS_GET_USER_DETAILS:
      return {
        ...previousState,
        userDetailsLoading: false,
        userDetailsData: action.userDetails,
      };
    case ERROR_GET_USER_DETAILS:
      return {
        ...previousState,
        userDetailsMessage: action.message,
        userDetailsLoading: false,
      };
    case START_GET_BANNER_LIST:
      return {
        ...previousState,
        bannerIsLoading: true,
        bannerErrorMessage: "",
      };
    case SUCCESS_GET_BANNER_LIST:
      return {
        ...previousState,
        bannerIsLoading: false,
        bannerData: action.banner,
      };
    case ERROR_GET_BANNER_LIST:
      return {
        ...previousState,
        bannerErrorMessage: action.message,
        bannerIsLoading: false,
      };
    case START_USER_ADDRESS_ACTION:
      return {
        ...previousState,
        addressIsLoading: true,
        addressErrorMessage: "",
        addressCreated: false,
      };
    case SUCCESS_USER_ADDRESS_ACTION:
      let { address_id, action_type, address_list } = action;
      let addressList = previousState.addressData;
      if (action_type === "get") {
        addressList = address_list;
      }
      if (action_type == "post") {
        addressList = [address_list].concat(addressList);
      }

      if (action_type == "delete") {
        addressList = previousState.addressData.filter((address) => {
          return address.id != decrypt(address_id);
        });
      }

      if (action_type == "put") {
        addressList = addressList.map((t) =>
          t.id == decrypt(address_id) ? address_list : t
        );
      }
      return {
        ...previousState,
        addressIsLoading: false,
        addressData: addressList,
        addressCreated:
          action_type == "put" ||
          action_type == "post" ||
          action_type == "delete"
            ? true
            : false,
      };
    case ERROR_USER_ADDRESS_ACTION:
      return {
        ...previousState,
        addressErrorMessage: action.message,
        addressIsLoading: false,
      };
    case START_UPDATE_USER_INFORMATION:
      return {
        ...previousState,
        userDetailsIsLoading: true,
        userInfoSuccessMessage: "",
        userInfoErrorMessage: "",
      };
    case SUCCESS_UPDATE_USER_INFORMATION:
      let new_user_details = action.user_details;
      let old_user_details = previousState.userDetailsData;
      old_user_details["name"] = new_user_details.name;
      old_user_details["email"] = new_user_details.email;
      old_user_details["m_number"] = new_user_details.m_number;
      return {
        ...previousState,
        userDetailsIsLoading: false,
        userDetailsData: old_user_details,
        userInfoSuccessMessage: action.message,
      };
    case ERROR_UPDATE_USER_INFORMATION:
      return {
        ...previousState,
        userDetailsIsLoading: false,
        userInfoErrorMessage: action.message,
      };
    case START_UPDATE_USER_PASSWORD:
      return {
        ...previousState,
        updatePasswordIsLoading: true,
        updatePasswordSuccessMessage: "",
        updatePasswordErrorMessage: "",
      };
    case SUCCESS_UPDATE_USER_PASSWORD:
      return {
        ...previousState,
        updatePasswordIsLoading: false,
        updatePasswordSuccessMessage: action.message,
      };
    case ERROR_UPDATE_USER_PASSWORD:
      return {
        ...previousState,
        updatePasswordIsLoading: false,
        updatePasswordErrorMessage: action.message,
      };
    case START_USER_FORGOT_PASSWORD:
      return {
        ...previousState,
        forgotPasswordIsLoading: true,
        passwordStatus: false,
        actionTypeStatus: "",
        forgotPasswordSuccessMessage: "",
        forgotPasswordErrorMessage: "",
      };
    case SUCCESS_USER_FORGOT_PASSWORD:
      return {
        ...previousState,
        forgotPasswordIsLoading: false,
        passwordStatus: true,
        actionTypeStatus: action.actionType,
        forgotPasswordSuccessMessage: action.message,
      };
    case ERROR_USER_FORGOT_PASSWORD:
      return {
        ...previousState,
        forgotPasswordIsLoading: false,
        forgotPasswordErrorMessage: action.message,
      };
    case START_USER_RESET_PASSWORD:
      return {
        ...previousState,
        resetPasswordIsLoading: true,
        passwordStatus: false,
        resetPasswordSuccessMessage: "",
        resetPasswordErrorMessage: "",
      };
    case SUCCESS_USER_RESET_PASSWORD:
      return {
        ...previousState,
        resetPasswordIsLoading: false,
        passwordStatus: true,
        resetPasswordSuccessMessage: action.message,
      };
    case ERROR_USER_RESET_PASSWORD:
      return {
        ...previousState,
        resetPasswordIsLoading: false,
        resetPasswordErrorMessage: action.message,
      };
    case START_GET_SUB_BANNER_LIST:
      return {
        ...previousState,
        subBannerIsLoading: true,
        subBannerData: [],
      };
    case SUCCESS_GET_SUB_BANNER_LIST:
      return {
        ...previousState,
        subBannerIsLoading: false,
        subBannerData: action.sub_banner,
      };
    case ERROR_GET_SUB_BANNER_LIST:
      return {
        ...previousState,
        subBannerData: [],
        subBannerIsLoading: false,
      };
    case START_GET_PAGE_DETAILS:
      return {
        ...previousState,
        pageDetails: "",
      };
    case SUCCESS_GET_PAGE_DETAILS:
      return {
        ...previousState,
        pageDetails: action.pageDetails,
      };
    case ERROR_GET_PAGE_DETAILS:
      return {
        ...previousState,
        pageDetails: "",
      };
    case START_GET_USER_SETTINGS:
      return {
        ...previousState,
      };
    case SUCCESS_GET_USER_SETTINGS:
      return {
        ...previousState,
        userSettingsDetails: action.user_settings,
      };
    case ERROR_GET_USER_SETTINGS:
      return {
        ...previousState,
      };
    case START_GET_ADMIN_VIDEO_LINK:
      return {
        ...previousState,
        videoLinkDataIsLoading: true,
        videoLinkData: [],
      };
    case SUCCESS_GET_ADMIN_VIDEO_LINK:
      return {
        ...previousState,
        videoLinkDataIsLoading: false,
        videoLinkData: action.videoData,
      };
    case ERROR_GET_ADMIN_VIDEO_LINK:
      return {
        ...previousState,
        videoLinkDataIsLoading: false,
      };
    case START_USER_VERIFY_OTP:
      return {
        ...previousState,
        userVerifyOTPIsLoading: true,
        userSignUpAndSignStatus: false,
      };
    case SUCCESS_USER_VERIFY_OTP:
      return {
        ...previousState,
        userVerifyOTPIsLoading: false,
        userSignUpAndSignStatus: true,
        userDetailsData: action.userDetails,
      };
    case ERROR_USER_VERIFY_OTP:
      return {
        ...previousState,
        userVerifyOTPIsLoading: false,
        userVerifyOTPErrorMessage: action.message,
      };
    case START_GET_ADS_BANNER_LIST:
      return {
        ...previousState,
        adsBannerIsLoading: true,
        adsBannerData: [],
      };
    case SUCCESS_GET_ADS_BANNER_LIST:
      return {
        ...previousState,
        adsBannerIsLoading: false,
        adsBannerData: action.ads_banner,
      };
    case ERROR_GET_ADS_BANNER_LIST:
      return {
        ...previousState,
        adsBannerData: [],
        adsBannerIsLoading: false,
      };
    default:
      return previousState || initState;
  }
};

export default myProfile;
