import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"

const RedirectionMiddleWare = () => {
    const navigate = useNavigate();
    const {url1,url2,url3} = useParams()
    let url = "/"+url1;
    if(url2){
        url+="/"+url2
    }
    if(url3){
        url+="/"+url3
    }

    console.log(url,"this is url")
    useEffect(() => {
        navigate(url)
    },[])
    return <></>
}

export default RedirectionMiddleWare