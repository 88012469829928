import { createMuiTheme, makeStyles } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  overrides: {
    MuiButton: {
      label: {
        textTransform: "capitalize",
      },
      contained: {
        color: "#FFF",
        backgroundColor: "#353b66",
        "&:hover": {
          backgroundColor: "#353b66",
          cursor: "pointer",
          opacity: 0.8,
        },
      },
      outlined: {
        color: "#353b66",
        backgroundColor: "#FFF",
        border: "1px solid #353b66",
        "&:hover": {
          backgroundColor: "#FFF",
          cursor: "pointer",
          opacity: 0.8,
        },
      },
      containedSecondary: {
        color: "#FFF",
        backgroundColor: "#353b66",
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        "&$checked": {
          color: "#FA002D",
        },
      },
    },
    MuiToolbar: {
      root: {
        backgroundColor: "#FA002D",
      },
    },
  },
  text: {
    boldText: {
      fontFamily: "Proxima Nova Rg",
    },
    mediumText: {
      fontFamily: "ProximaNova-Medium",
    },
    normalText: {
      fontFamily: "Proxima Nova Rg",
    },
    semiBoldText: {
      fontFamily: "ProximaNova-Semibold",
    },
  },
});

export const useCommonStyles = makeStyles((theme) => {
  return {};
});
